import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../../Components/Particle.js";
import Home2 from "./Home2";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import sliderConfig from '../../Assets/Slider';


function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Row>
          <Col md={12} className="home-header">
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              showThumbs={false}
              swipeable={true}
              interval={5000}
              stopOnHover={true}

            >
              {
                sliderConfig.map((item) => <div key={item.id}>
                  <img src={item.image} alt={item.id} />
                  {(item.heading || item.subheading) &&<div className="legend legendStyle">
                    <h4>{item.heading}</h4>
                    <p>{item.subheading}</p>
                  </div>}
                </div>)
              }
            </Carousel>
          </Col>
        </Row>
      </Container>
  
      <Home2 />
    </section>
  );
}

export default Home;
