import React from "react";
import Card from "react-bootstrap/Card";
import { Col } from "react-bootstrap";
import moment from "moment";
import { Carousel } from "react-responsive-carousel";
import { API_BASE } from "../../Config";

function FeedCard({
  detailed,
  feedLink,
  type,
  media,
  feedId,
  approved,
  archived,
  deleted,
  date,
  feedMessage,
  headline,
  views,
  location,
  members,
  onClick,
  userId, ...props }) {

  return (
    <Col md={type === 'sm' ? 2 : 12} className="project-card" onClick={onClick}>
      <Card className={detailed?"project-card-view": "project-card-view-small"}>
        {detailed && <>
          {media?.length === 1 ? <Card.Img variant="top" src={media[0].path} alt="card-img" /> :
            <Carousel
              autoPlay={true}
              infiniteLoop={true}
              showThumbs={false}
              swipeable={true}
              interval={5000}
              stopOnHover={true}
              showIndicators={false}
              showArrows={false}
              dynamicHeight={false}
            >
              {
                media.map((item,index) => {
                  let imagePath=item.path
                  if(imagePath){
                    if(!imagePath.includes('upload')){
                      imagePath=API_BASE+'/upload/'+imagePath
                    }else{
                      imagePath=API_BASE+imagePath
                    }
                  }
                  console.log("imagePath",imagePath)
                return <Card.Img variant="top" src={imagePath} alt="Image Loading" key={index}  style={{ height: "600px", objectFit: "cover", width: "100%", objectPosition: "top" }}/>
              })
              }
            </Carousel>}</>}
        <Card.Body>
          {!detailed&&<>
          <h1 className="project-heading2">
           {headline} <strong className="mainText">  </strong>
         </h1>
        <Card.Title ><span className="mainText">{moment(date,'DD/MM/YYYY').format('LL')}</span></Card.Title></>}
          <Card.Text style={{ textAlign: "justify" }}>
            <span> {feedMessage} </span>
          </Card.Text>

          <Card.Text style={{ textAlign: "justify" }}>
            Place : <span className="mainText">{location}</span>
          </Card.Text>
          <div className="cardRow">
            <Card.Text style={{ textAlign: "justify" }}>
              Presence Of : <span className="mainText">{members && members.join(', ')}</span>
            </Card.Text>
             <Card.Text style={{ textAlign: "justify" }}>
              Views : <span className="mainText">{views}</span>
            </Card.Text>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}
export default FeedCard;
