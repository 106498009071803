import React, { useState, useEffect } from "react";
import Preloader from "./Components/Pre";
import Navbar from "./Components/Navbar";
import Home from "./Screens/Home/Home";
import Login from "./Screens/Login";
import Register from "./Screens/Register";
import Activity from "./Screens/Activity";
import ActivityDetail from "./Screens/Activity/ActivityDetail";
import Achiever from "./Screens/Achiever";
import Members from "./Screens/Members";
import MemberDetail from "./Screens/Members/MemberDetail";
import Footer from "./Components/Footer";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ComingSoon from "./Screens/SiteState/ComingSoon";
import './Utils/i18n';
import AchievementDetail from "./Screens/Achiever/AchievementDetail";
import { END_POINT_CONFIG, END_POINT_MEMBERS } from "./Config";
import axios from "axios";
import ResumeBuilder from './Screens/ResumeBuilder'


function App() {
  const [load, upadateLoad] = useState(true);
  const [loading, setLoading] = useState(true);
  const [configData, setConfig] = useState({});

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  async function makeApiCall() {
    setLoading(true)
    const response = await axios.get(END_POINT_CONFIG);
    setConfig(response.data.data)
    setLoading(false)
  }

  useEffect(() => {
    makeApiCall()
  }, [])

  return (
    <Router>
      <Preloader load={load || loading} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar isLaunched={configData.STATE === 'launch'}/>
        <ScrollToTop />
        {configData.STATE === 'launch' ?
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/members" element={<Members />} />
            <Route path="/members/:id" element={<MemberDetail />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/resume-builder" element={<ResumeBuilder />} />
            <Route path="/register/:profileUrl" element={<Register />} />
            <Route path="/activity" element={<Activity />} />
            <Route path="/activity/:id" element={<ActivityDetail />} />
            <Route path="/achiever" element={<Achiever />} />
            <Route path="/achiever/:id" element={<AchievementDetail />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes> : <ComingSoon />}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
