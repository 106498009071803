import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import Particle from "../../Components/Particle.js";
import MemberCard from "./MemberCard";
import { useNavigate } from "react-router-dom";
import memberConfigData from './mock'
import axios from "axios";
import { API_BASE, END_POINT_MEMBERS } from "../../Config/index.js";
import Preloader from "../../Components/Pre";


const generalMemberConfigData = new Array(10).fill(memberConfigData[0])


function Members() {
  const [generalMemberData, setGeneralMemberData] = useState(generalMemberConfigData)
  const [memberData, setMemberData] = useState([])
  const [loading, setLoading] = useState(false)
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  async function makeApiCall(){
    setLoading(true)
    const response = await axios.get(END_POINT_MEMBERS);
    setMemberData(response.data.data)
    setLoading(false)
  }

  useEffect(() => {
    makeApiCall()
  }, [])

  function onClick(data) {
    navigate(`/members/${data.profileUrl}`, { id: { id: data.profileUrl } });
  }



  return (
    <Container fluid className="project-section">
      <Preloader load={loading} />
      <Particle />
      <Container>
        <h1 className="project-heading">
          {t('Active')} <strong className="mainText"> {t('Members')} </strong>
        </h1>
        <p style={{ color: "white" }}>
          {t("Here are a few helping hands.")}
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {memberData.filter(item=>!item.archived).map((item,index) =>{
            return <MemberCard
              key={item.userId}
              onPress={() => onClick(item)}
              type={"lg"}
              userId={item.userId}
              profileUrl={item.profileUrl}
              memberId={item.memberId}
              email={item.email}
              mobile={item.mobile}
              password={item.password}
              level={item.level}
              isValidatedMobile={item.isValidatedMobile}
              isValidatedEmail={item.isValidatedEmail}
              firstTime={item.firstTime}
              memberType={item.memberType}
              profileImage={API_BASE+item.profileImage.path}
              firstName={item.firstName}
              middleName={item.middleName}
              lastName={item.lastName}
              fatherName={item.fatherName}
              dob={item.dob}
              gender={item.gender}
              addressLine1={item.addressLine1}
              addressLine2={item.addressLine2}
              landMark={item.landMark}
              state={item.state}
              city={item.city}
              pinCode={item.pinCode}
              higherEducation={item.higherEducation}
              occupation={item.occupation}
              profession={item.profession}
              piDocumentType={item.piDocumentType}
              piDocumentId={item.piDocumentId}
              piDocumentPath={item.piDocumentPath}
              bloodGroup={item.bloodGroup}
              memberDesignation={item.memberDesignation}
              clan={item.clan}
            />
          })}

        </Row>
        {/* <h1 className="project-heading">
          {t('General')} <strong className="mainText"> {t('Members')} </strong>
        </h1>
        <p style={{ color: "white" }}>
          {t("Here are our contributors.")}
        </p>
        <Row
          style={{ justifyContent: "center", paddingBottom: "10px" }}
        >
          <InfiniteScroll
            dataLength={generalMemberData.length}
            next={() => {
              console.log("call next")

              setGeneralMemberData([...generalMemberData, ...generalMemberConfigData])
            }}
            height={600}
            style={{
              justifyContent: "center",
              paddingBottom: "10px",
              overflow: 'auto',
              display: 'flex',
              flexWrap: 'wrap'
            }}
            inverse={false} //
            hasMore={true}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {generalMemberData.map((item, index) => (
              <MemberCard
                onPress={() => onClick(item)}
                key={item.id + index}
                type={"sm"}
                userId={item.userId}
                profileUrl={item.profileUrl}
                memberId={item.memberId}
                email={item.email}
                mobile={item.mobile}
                password={item.password}
                level={item.level}
                isValidatedMobile={item.isValidatedMobile}
                isValidatedEmail={item.isValidatedEmail}
                firstTime={item.firstTime}
                memberType={item.memberType}
                profileImage={item.profileImage}
                firstName={item.firstName}
                middleName={item.middleName}
                lastName={item.lastName}
                fatherName={item.fatherName}
                dob={item.dob}
                gender={item.gender}
                addressLine1={item.addressLine1}
                addressLine2={item.addressLine2}
                landMark={item.landMark}
                state={item.state}
                city={item.city}
                pinCode={item.pinCode}
                higherEducation={item.higherEducation}
                occupation={item.occupation}
                profession={item.profession}
                piDocumentType={item.piDocumentType}
                piDocumentId={item.piDocumentId}
                piDocumentPath={item.piDocumentPath}
                bloodGroup={item.bloodGroup}
                clan={item.clan}
              />
            ))}
          </InfiniteScroll> 
        </Row> */}
      </Container>
    </Container>
  );
}

export default Members;
