import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import Particle from "../../Components/Particle.js";
import MemberCard from "./FeedCard";
import _find from 'lodash/find'

import feeds from './mock'
import FeedCard from "./FeedCard";
import { useParams } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { END_POINT_ACTIVITY } from "../../Config/index.js";
import Preloader from "../../Components/Pre";


function ActivityDetail() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [activityDetailData, setActivityDetailData] = useState({})
  const [loading, setLoading] = useState(false)

  async function makeApiCall() {
    setLoading(true)
    const response = await axios.get(END_POINT_ACTIVITY + '/' + id);
    setActivityDetailData(response.data.data)
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      makeApiCall()
    }
  }, [id])

  return (
    <Container fluid className="project-section">
      <Particle />
      <Preloader load={loading} />
      <Container>
        <h1 className="project-heading">
          {activityDetailData.headline} <strong className="mainText">  </strong>
        </h1>
        <p className="mainText">
          {moment(activityDetailData.date, 'DD/MM/YYYY').format('LL')}
        </p>
        <Row
          style={{ justifyContent: "center", paddingBottom: "10px" }}
        >
          <FeedCard
            detailed
            key={activityDetailData.feedId}
            media={activityDetailData.media||[]}
            feedId={activityDetailData.feedId}
            approved={activityDetailData.approved}
            archived={activityDetailData.archived}
            created_at={activityDetailData.created_at}
            date={activityDetailData.date}
            deleted={activityDetailData.deleted}
            feedMessage={activityDetailData.feedMessage}
            headline={activityDetailData.headline}
            views={activityDetailData.views}
            location={activityDetailData.location}
            members={activityDetailData.members}
            updated_at={activityDetailData.updated_at}
            userId={activityDetailData.userId}
          />

        </Row>
      </Container>
    </Container>
  );
}

export default ActivityDetail;
