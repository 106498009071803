import React, { Component, Fragment } from "react";
import Dropzone from "../dropzone";
import Progress from "../progress";
import { END_POINT_UPLOAD, API_BASE } from "../../../Config";
import { Image, Button, Row, Col } from "react-bootstrap";
import axios from "axios";
import svgIcon from "./baseline-check_circle_outline-24px.svg";
import "./styles.css";
import Webcam from "react-webcam";
class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullyUploaded: false,
      images: [],
      showCamera: false
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.handleCamera = this.handleCamera.bind(this);
    this.capture = this.capture.bind(this);
  }

  onFilesAdded(files) {
    if (this.props.single) {
      this.setState({ files: files });
    } else {
      this.setState(prevState => ({
        files: prevState.files.concat(files)
      }));
    }
    if (this.props.uploadOnSelect) {
      this.uploadFiles();
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps && nextProps.images && nextProps.images.length) {
      if (
        this.props.images &&
        this.props.images.length !== nextProps.images.length &&
        nextProps.images.length !== this.state.images.length
      ) {
        this.setState({ images: nextProps.images });
      }
    }
  }

  handleCamera(e) {
    e && e.preventDefault();
    this.setState({ showCamera: !this.state.showCamera });
  }

  // async uploadFiles(e) {
  //   this.setState({ uploadProgress: {}, uploading: true });
  //   const promises = [];
  //   this.state.files.forEach(file => {
  //     promises.push(this.sendRequest(file));
  //   });
  //   try {
  //     await Promise.all(promises)
  //     this.setState({ successfullUploaded: true, uploading: false });
  //   } catch (e) {
  //     // Not Production ready! Do some error handling here instead...
  //     this.setState({ successfullUploaded: true, uploading: false });
  //   }
  //   //e.preventDefault();
  // }

  async uploadFiles(e) {
    e && e.preventDefault();
    let self = this;
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    this.state.files.forEach(file => {
      let data = new FormData();
      if (file.blob) {
        data.append("file", file.blob, file.name);
      } else {
        data.append("file", file, file.name);
      }
      promises.push(
        axios.post(END_POINT_UPLOAD, data, {
          onUploadProgress: function(event) {
            const percentCompleted = Math.round(
              (event.loaded * 100) / event.total
            );
            const copy = { ...self.state.uploadProgress };
            copy[file.name] = {
              state: "pending",
              percentage: percentCompleted
            };
            self.setState({ uploadProgress: copy });
          }
        })
      );
    });
    try {
      let images = await Promise.all(promises);
      images = images.reduce((acc, item) => {
        return acc.concat(item.data.result);
      }, []);
      this.props.getFilesPath(images);
      this.setState({ successfullUploaded: true, uploading: false, images });
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
    //e.preventDefault();
  }

  sendRequest(file) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      req.upload.addEventListener("progress", event => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener("load", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
        resolve(req.response);
      });

      req.upload.addEventListener("error", event => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });

        reject(req.response);
      });

      const formData = new FormData();
      formData.append("file", file, file.name);
      req.open("POST", END_POINT_UPLOAD, false);
      req.send(formData);
    });
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullyUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <img
            className="CheckIcon"
            alt="done"
            src={svgIcon}
            style={{
              opacity:
                uploadProgress && uploadProgress.percentage >= 100 ? 0.5 : 0
            }}
          />
        </div>
      );
    }
  }

  renderActions() {
    if (this.state.successfullyUploaded) {
      return (
        <button
          onClick={e => {
            e.preventDefault();
            this.setState({ files: [], successfullUploaded: false });
          }}
        >
          Clear
        </button>
      );
    } else {
      return (
        <Row>
          <Col>
            <Button variant={"info"} onClick={this.handleCamera}>
              Open Camera
            </Button>
          </Col>
          <Col>
            <Button
              disabled={this.state.files.length <= 0 || this.state.uploading}
              onClick={this.uploadFiles}
              variant={"success"}
            >
              Upload
            </Button>
          </Col>
        </Row>
      );
    }
  }

  removeImage(e, i) {
    let { images } = this.state;
    images.splice(i, 1);
    this.setState({ images }, () => {
      this.props.getFilesPath(images);
    });
  }
  renderImages(images) {
    return (
      <div
        style={{
          display: "flex",
          flex: 1
        }}
      >
        {images.map((item, i) => {
          return (
            <div
              key={i}
              style={{
                display: "flex",
                flex: 0.3,
                padding: "5px",
                margin: "5px"
              }}
              onClick={e => this.removeImage(e, i)}
            >
              <Image src={API_BASE + item?.path} width={"50px"} />
            </div>
          );
        })}
      </div>
    );
  }

  setRef = webcam => {
    this.webcam = webcam;
  };
  dataURLtoBlob(dataurl) {
    if (dataurl) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    }
  }

  capture(e) {
    e.preventDefault();
    const imageSrc = this.webcam.getScreenshot();
    const imageObject = this.dataURLtoBlob(imageSrc);
    this.onFilesAdded([
      {
        type: imageObject.type,
        name: `webCam Capture${this.state.files.length + 1}.jpeg`,
        blob: imageObject
      }
    ]);
    this.handleCamera();
  }
  render() {
    let { images } = this.state;
    const videoConstraints = {
      width: 720,
      height: 720,
      facingMode: "user"
    };
    return (
      <div className="Upload">
        <span className="Title">Upload Files</span>
        {this.renderImages(images)}
        <div className="Content">
          {!this.state.showCamera && (
            <div>
              <Dropzone
                onFilesAdded={this.onFilesAdded}
                disabled={
                  this.props.uploadOnSelect
                    ? this.state.uploading
                    : this.state.uploading || this.state.successfullyUploaded
                }
              />
            </div>
          )}
          <div className="Files">
            {this.state.files.map(file => {
              return (
                <div key={file.name} className="Row">
                  <span className="Filename">{file.name}</span>
                  {this.renderProgress(file)}
                </div>
              );
            })}
          </div>
        </div>
        {this.props.uploadOnSelect || this.state.showCamera ? null : (
          <div className="Actions">{this.renderActions()}</div>
        )}
        {this.props.capture && (
          <Fragment>
            {this.state.showCamera && (
              <Fragment>
                <Webcam
                  audio={false}
                  height={350}
                  ref={this.setRef}
                  screenshotFormat="image/jpeg"
                  width={350}
                  videoConstraints={videoConstraints}
                />
                <div className="Actions">
                  <Row>
                    <Col>
                      <Button
                        variant={"outline-danger"}
                        onClick={this.handleCamera}
                      >
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button variant={"success"} onClick={this.capture}>
                        Capture photo
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

export default Main;
