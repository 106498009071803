import nov_03_2021_1 from './PHOTO-2023-01-05-18-18-19.jpg'
import nov_03_2021_2 from './PHOTO-2023-01-05-18-18-20.jpg'
import nov_11_2021_1 from './PHOTO-2023-01-05-18-18-56.jpg'
import dec_26_2021_1 from './PHOTO-2023-01-05-18-21-53.jpg'
import dec_26_2021_2 from './PHOTO-2023-01-05-18-21-54.jpg'
import dec_26_2021_3 from './PHOTO-2023-01-05-18-21-55-2.jpg'
import dec_26_2021_4 from './PHOTO-2023-01-05-18-21-55-2.jpg'
import dec_26_2021_5 from './PHOTO-2023-01-05-18-21-55.jpg'
import dec_26_2021_6 from './PHOTO-2023-01-05-18-21-56-2.jpg'
import dec_26_2021_7 from './PHOTO-2023-01-05-18-21-56-3.jpg'
import dec_26_2021_8 from './PHOTO-2023-01-05-18-21-56.jpg'
import dec_26_2021_9 from './PHOTO-2023-01-05-18-21-59-2.jpg'
import dec_26_2021_10 from './PHOTO-2023-01-05-18-21-59.jpg'
import dec_26_2021_11 from './PHOTO-2023-01-05-18-22-00.jpg'
import dec_26_2021_12 from './PHOTO-2023-01-05-18-22-01-2.jpg'
import dec_26_2021_13 from './PHOTO-2023-01-05-18-22-01-3.jpg'
import dec_26_2021_14 from './PHOTO-2023-01-05-18-22-01.jpg'
import dec_26_2021_15 from './PHOTO-2023-01-05-18-22-02-2.jpg'
import dec_26_2021_16 from './PHOTO-2023-01-05-18-22-02.jpg'
import feb_10_2022_1 from './PHOTO-2023-01-05-18-23-13.jpg'
import feb_10_2022_2 from './PHOTO-2023-01-05-18-23-14.jpg'
import march_17_2022_1 from './PHOTO-2023-01-05-18-24-50.jpg'
import apr_06_2022_1 from './PHOTO-2023-01-05-18-26-49.jpg'
import apr_06_2022_2 from './PHOTO-2023-01-05-18-26-50-2.jpg'
import apr_06_2022_3 from './PHOTO-2023-01-05-18-26-50.jpg'
import apr_06_2022_4 from './PHOTO-2023-01-05-18-26-51.jpg'
import apr_07_2022_1 from './PHOTO-2023-01-05-18-28-12.jpg'
import apr_07_2022_2 from './PHOTO-2023-01-05-18-28-13.jpg'
import apr_11_2022_1 from './PHOTO-2023-01-05-18-29-43.jpg'
import apr_22_2022_1 from './PHOTO-2023-01-05-18-30-48-1.jpg'
import apr_22_2022_2 from './PHOTO-2023-01-05-18-30-48.jpg'
import apr_25_2022_1 from './PHOTO-2023-01-05-18-31-52.jpg'
import apr_25_2022_2 from './PHOTO-2023-01-05-18-31-53.jpg'
import apr_28_2022_1 from './PHOTO-2023-01-05-18-33-12.jpg'
import may_01_2022_1 from './PHOTO-2023-01-05-18-34-30.jpg'
import may_01_2022_2 from './PHOTO-2023-01-05-18-34-31.jpg'
import may_05_2022_1 from './PHOTO-2023-01-05-18-35-47.jpg'
import may_17_2022_1 from './PHOTO-2023-01-05-18-36-57.jpg'
import may_21_2022_1 from './PHOTO-2023-01-05-18-39-08.jpg'
import may_21_2022_2 from './PHOTO-2023-01-05-18-39-09.jpg'
import june_16_2022_1 from './PHOTO-2023-01-05-18-40-08.jpg'
import june_16_2022_2 from './PHOTO-2023-01-05-18-40-11.jpg'
import june_16_2022_3 from './PHOTO-2023-01-05-18-40-12-2.jpg'
import june_16_2022_4 from './PHOTO-2023-01-05-18-40-12.jpg'
import june_16_2022_5 from './PHOTO-2023-01-05-18-40-13-2.jpg'
import june_16_2022_6 from './PHOTO-2023-01-05-18-40-13.jpg'
import june_28_2022_1 from './PHOTO-2023-01-05-18-41-35.jpg'
import june_28_2022_2 from './PHOTO-2023-01-05-18-41-18.jpg'
import june_28_2022_3 from './PHOTO-2023-01-05-18-41-35.jpg'
import jul_21_2022_1 from './PHOTO-2023-01-05-18-41-35.jpg'
import jul_21_2022_2 from './PHOTO-2023-01-05-18-44-53-2.jpg'
import jul_21_2022_3 from './PHOTO-2023-01-05-18-44-53.jpg'
import aug_10_2022_1 from './PHOTO-2023-01-05-19-06-09.jpg'
import sep_01_2022_1 from './PHOTO-2023-01-05-19-08-24.jpg'
import sep_01_2022_2 from './PHOTO-2023-01-05-19-08-29.jpg'
import sep_01_2022_3 from './PHOTO-2023-01-05-19-08-24.jpg'
import sep_01_2022_4 from './PHOTO-2023-01-05-19-08-47.jpg'
import sep_01_2022_5 from './PHOTO-2023-01-05-19-08-48-2.jpg'
import sep_01_2022_6 from './PHOTO-2023-01-05-19-08-48.jpg'
import sep_01_2022_7 from './PHOTO-2023-01-05-19-08-49-2.jpg'
import sep_01_2022_8 from './PHOTO-2023-01-05-19-08-49.jpg'
import sep_18_2022_1 from './PHOTO-2023-01-05-19-10-40.jpg'
import oct_07_2022_1 from './PHOTO-2023-01-05-19-13-11-2.jpg'
import oct_07_2022_2 from './PHOTO-2023-01-05-19-13-11.jpg'
import oct_07_2022_3 from './PHOTO-2023-01-05-19-13-12.jpg'
import oct_20_2022_1 from './PHOTO-2023-01-05-19-16-48-2.jpg'
import oct_20_2022_2 from './PHOTO-2023-01-05-19-16-48.jpg'
import oct_27_2022_1 from './PHOTO-2023-01-05-19-53-02.jpg'
import oct_27_2022_2 from './PHOTO-2023-01-05-19-53-03.jpg'
import nov_27_2022_1 from './PHOTO-2023-01-05-19-55-11.jpg'
import nov_27_2022_2 from './PHOTO-2023-01-05-19-55-12.jpg'
import nov_27_2022_3 from './PHOTO-2023-01-05-19-55-13-2.jpg'
import nov_27_2022_4 from './PHOTO-2023-01-05-19-55-13.jpg'
import nov_29_2022_1 from './PHOTO-2023-01-05-19-58-00-3.jpg'
import nov_29_2022_2 from './PHOTO-2023-01-05-19-58-00-4.jpg'
import dec_02_2022_1 from './PHOTO-2023-01-05-20-01-17-2.jpg'
import dec_02_2022_2 from './PHOTO-2023-01-05-20-01-17.jpg'
import dec_02_2022_3 from './PHOTO-2023-01-05-20-01-18-2.jpg'
import dec_02_2022_4 from './PHOTO-2023-01-05-20-01-18.jpg'
import dec_02_2022_5 from './PHOTO-2023-01-05-20-01-30.jpg'
import dec_02_2022_6 from './PHOTO-2023-01-05-20-01-31.jpg'
import dec_11_2022_1 from './PHOTO-2023-01-05-20-03-29.jpg'
import dec_11_2022_2 from './PHOTO-2023-01-05-20-03-30.jpg'
import dec_13_2022_1 from './PHOTO-2023-01-05-20-05-35.jpg'
import dec_13_2022_2 from './PHOTO-2023-01-05-20-05-39.jpg'
import dec_13_2022_3 from './PHOTO-2023-01-05-20-05-39-2.jpg'
import dec_13_2022_4 from './PHOTO-2023-01-05-20-05-39.jpg'
import dec_13_2022_5 from './PHOTO-2023-01-05-20-05-40.jpg'
import dec_13_2022_6 from './PHOTO-2023-01-05-20-05-40-2.jpg'
import dec_13_2022_7 from './PHOTO-2023-01-05-20-05-40-3.jpg'
import dec_13_2022_8 from './PHOTO-2023-01-05-20-05-41-2.jpg'
import dec_13_2022_9 from './PHOTO-2023-01-05-20-05-41.jpg'
import dec_13_2022_10 from './PHOTO-2023-01-05-20-05-42.jpg'
import dec_17_2022_1 from './PHOTO-2023-01-05-20-06-59-2.jpg'
import dec_17_2022_2 from './PHOTO-2023-01-05-20-06-59.jpg'



const images = {
    nov_03_2021_1,
    nov_03_2021_2,
    nov_11_2021_1,
    dec_26_2021_1,
    dec_26_2021_2,
    dec_26_2021_3,
    dec_26_2021_4,
    dec_26_2021_5,
    dec_26_2021_6,
    dec_26_2021_7,
    dec_26_2021_8,
    dec_26_2021_9,
    dec_26_2021_10,
    dec_26_2021_11,
    dec_26_2021_12,
    dec_26_2021_13,
    dec_26_2021_14,
    dec_26_2021_15,
    dec_26_2021_16,
    feb_10_2022_1,
    feb_10_2022_2,
    march_17_2022_1,
    apr_06_2022_1,
    apr_06_2022_2,
    apr_06_2022_3,
    apr_06_2022_4,
    apr_07_2022_1,
    apr_07_2022_2,
    apr_11_2022_1,
    apr_22_2022_1,
    apr_22_2022_2,
    apr_25_2022_1,
    apr_25_2022_2,
    apr_28_2022_1,
    may_01_2022_1,
    may_01_2022_2,
    may_05_2022_1,
    may_17_2022_1,
    may_21_2022_1,
    may_21_2022_2,
    june_16_2022_1,
    june_16_2022_2,
    june_16_2022_3,
    june_16_2022_4,
    june_16_2022_5,
    june_16_2022_6,
    june_28_2022_1,
    june_28_2022_2,
    june_28_2022_3,
    jul_21_2022_1,
    jul_21_2022_2,
    jul_21_2022_3,
    aug_10_2022_1,
    sep_01_2022_1,
    sep_01_2022_2,
    sep_01_2022_3,
    sep_01_2022_4,
    sep_01_2022_5,
    sep_01_2022_6,
    sep_01_2022_7,
    sep_01_2022_8,
    sep_18_2022_1,
    oct_07_2022_1,
    oct_07_2022_2,
    oct_07_2022_3,
    oct_20_2022_1,
    oct_20_2022_2,
    oct_27_2022_1,
    oct_27_2022_2,
    nov_27_2022_1,
    nov_27_2022_2,
    nov_27_2022_3,
    nov_27_2022_4,
    nov_29_2022_1,
    nov_29_2022_2,
    dec_02_2022_1,
    dec_02_2022_2,
    dec_02_2022_3,
    dec_02_2022_4,
    dec_02_2022_5,
    dec_02_2022_6,
    dec_11_2022_1,
    dec_11_2022_2,
    dec_13_2022_1,
    dec_13_2022_2,
    dec_13_2022_3,
    dec_13_2022_4,
    dec_13_2022_5,
    dec_13_2022_6,
    dec_13_2022_7,
    dec_13_2022_8,
    dec_13_2022_9,
    dec_13_2022_10,
    dec_17_2022_1,
    dec_17_2022_2,
}

export default images

export const imagesConfig={
    "nov_03_2021_1":'PHOTO-2023-01-05-18-18-19.jpg',
    "nov_03_2021_2":'PHOTO-2023-01-05-18-18-20.jpg',
    "nov_11_2021_1":'PHOTO-2023-01-05-18-18-56.jpg',
    "dec_26_2021_1":'PHOTO-2023-01-05-18-21-53.jpg',
    "dec_26_2021_2":'PHOTO-2023-01-05-18-21-54.jpg',
    "dec_26_2021_3":'PHOTO-2023-01-05-18-21-55-2.jpg',
    "dec_26_2021_4":'PHOTO-2023-01-05-18-21-55-2.jpg',
    "dec_26_2021_5":'PHOTO-2023-01-05-18-21-55.jpg',
    "dec_26_2021_6":'PHOTO-2023-01-05-18-21-56-2.jpg',
    "dec_26_2021_7":'PHOTO-2023-01-05-18-21-56-3.jpg',
    "dec_26_2021_8":'PHOTO-2023-01-05-18-21-56.jpg',
    "dec_26_2021_9":'PHOTO-2023-01-05-18-21-59-2.jpg',
    "dec_26_2021_10":'PHOTO-2023-01-05-18-21-59.jpg',
    "dec_26_2021_11":'PHOTO-2023-01-05-18-22-00.jpg',
    "dec_26_2021_12":'PHOTO-2023-01-05-18-22-01-2.jpg',
    "dec_26_2021_13":'PHOTO-2023-01-05-18-22-01-3.jpg',
    "dec_26_2021_14":'PHOTO-2023-01-05-18-22-01.jpg',
    "dec_26_2021_15":'PHOTO-2023-01-05-18-22-02-2.jpg',
    "dec_26_2021_16":'PHOTO-2023-01-05-18-22-02.jpg',
    "feb_10_2022_1":'PHOTO-2023-01-05-18-23-13.jpg',
    "feb_10_2022_2":'PHOTO-2023-01-05-18-23-14.jpg',
    "march_17_2022_1":'PHOTO-2023-01-05-18-24-50.jpg',
    "apr_06_2022_1":'PHOTO-2023-01-05-18-26-49.jpg',
    "apr_06_2022_2":'PHOTO-2023-01-05-18-26-50-2.jpg',
    "apr_06_2022_3":'PHOTO-2023-01-05-18-26-50.jpg',
    "apr_06_2022_4":'PHOTO-2023-01-05-18-26-51.jpg',
    "apr_07_2022_1":'PHOTO-2023-01-05-18-28-12.jpg',
    "apr_07_2022_2":'PHOTO-2023-01-05-18-28-13.jpg',
    "apr_11_2022_1":'PHOTO-2023-01-05-18-29-43.jpg',
    "apr_22_2022_1":'PHOTO-2023-01-05-18-30-48-1.jpg',
    "apr_22_2022_2":'PHOTO-2023-01-05-18-30-48.jpg',
    "apr_25_2022_1":'PHOTO-2023-01-05-18-31-52.jpg',
    "apr_25_2022_2":'PHOTO-2023-01-05-18-31-53.jpg',
    "apr_28_2022_1":'PHOTO-2023-01-05-18-33-12.jpg',
    "may_01_2022_1":'PHOTO-2023-01-05-18-34-30.jpg',
    "may_01_2022_2":'PHOTO-2023-01-05-18-34-31.jpg',
    "may_05_2022_1":'PHOTO-2023-01-05-18-35-47.jpg',
    "may_17_2022_1":'PHOTO-2023-01-05-18-36-57.jpg',
    "may_21_2022_1":'PHOTO-2023-01-05-18-39-08.jpg',
    "may_21_2022_2":'PHOTO-2023-01-05-18-39-09.jpg',
    "june_16_2022_1":'PHOTO-2023-01-05-18-40-08.jpg',
    "june_16_2022_2":'PHOTO-2023-01-05-18-40-11.jpg',
    "june_16_2022_3":'PHOTO-2023-01-05-18-40-12-2.jpg',
    "june_16_2022_4":'PHOTO-2023-01-05-18-40-12.jpg',
    "june_16_2022_5":'PHOTO-2023-01-05-18-40-13-2.jpg',
    "june_16_2022_6":'PHOTO-2023-01-05-18-40-13.jpg',
    "june_28_2022_1":'PHOTO-2023-01-05-18-41-35.jpg',
    "june_28_2022_2":'PHOTO-2023-01-05-18-41-18.jpg',
    "june_28_2022_3":'PHOTO-2023-01-05-18-41-35.jpg',
    "jul_21_2022_1":'PHOTO-2023-01-05-18-41-35.jpg',
    "jul_21_2022_2":'PHOTO-2023-01-05-18-44-53-2.jpg',
    "jul_21_2022_3":'PHOTO-2023-01-05-18-44-53.jpg',
    "aug_10_2022_1":'PHOTO-2023-01-05-19-06-09.jpg',
    "sep_01_2022_1":'PHOTO-2023-01-05-19-08-24.jpg',
    "sep_01_2022_2":'PHOTO-2023-01-05-19-08-29.jpg',
    "sep_01_2022_3":'PHOTO-2023-01-05-19-08-24.jpg',
    "sep_01_2022_4":'PHOTO-2023-01-05-19-08-47.jpg',
    "sep_01_2022_5":'PHOTO-2023-01-05-19-08-48-2.jpg',
    "sep_01_2022_6":'PHOTO-2023-01-05-19-08-48.jpg',
    "sep_01_2022_7":'PHOTO-2023-01-05-19-08-49-2.jpg',
    "sep_01_2022_8":'PHOTO-2023-01-05-19-08-49.jpg',
    "sep_18_2022_1":'PHOTO-2023-01-05-19-10-40.jpg',
    "oct_07_2022_1":'PHOTO-2023-01-05-19-13-11-2.jpg',
    "oct_07_2022_2":'PHOTO-2023-01-05-19-13-11.jpg',
    "oct_07_2022_3":'PHOTO-2023-01-05-19-13-12.jpg',
    "oct_20_2022_1":'PHOTO-2023-01-05-19-16-48-2.jpg',
    "oct_20_2022_2":'PHOTO-2023-01-05-19-16-48.jpg',
    "oct_27_2022_1":'PHOTO-2023-01-05-19-53-02.jpg',
    "oct_27_2022_2":'PHOTO-2023-01-05-19-53-03.jpg',
    "nov_27_2022_1":'PHOTO-2023-01-05-19-55-11.jpg',
    "nov_27_2022_2":'PHOTO-2023-01-05-19-55-12.jpg',
    "nov_27_2022_3":'PHOTO-2023-01-05-19-55-13-2.jpg',
    "nov_27_2022_4":'PHOTO-2023-01-05-19-55-13.jpg',
    "nov_29_2022_1":'PHOTO-2023-01-05-19-58-00-3.jpg',
    "nov_29_2022_2":'PHOTO-2023-01-05-19-58-00-4.jpg',
    "dec_02_2022_1":'PHOTO-2023-01-05-20-01-17-2.jpg',
    "dec_02_2022_2":'PHOTO-2023-01-05-20-01-17.jpg',
    "dec_02_2022_3":'PHOTO-2023-01-05-20-01-18-2.jpg',
    "dec_02_2022_4":'PHOTO-2023-01-05-20-01-18.jpg',
    "dec_02_2022_5":'PHOTO-2023-01-05-20-01-30.jpg',
    "dec_02_2022_6":'PHOTO-2023-01-05-20-01-31.jpg',
    "dec_11_2022_1":'PHOTO-2023-01-05-20-03-29.jpg',
    "dec_11_2022_2":'PHOTO-2023-01-05-20-03-30.jpg',
    "dec_13_2022_1":'PHOTO-2023-01-05-20-05-35.jpg',
    "dec_13_2022_2":'PHOTO-2023-01-05-20-05-39.jpg',
    "dec_13_2022_3":'PHOTO-2023-01-05-20-05-39-2.jpg',
    "dec_13_2022_4":'PHOTO-2023-01-05-20-05-39.jpg',
    "dec_13_2022_5":'PHOTO-2023-01-05-20-05-40.jpg',
    "dec_13_2022_6":'PHOTO-2023-01-05-20-05-40-2.jpg',
    "dec_13_2022_7":'PHOTO-2023-01-05-20-05-40-3.jpg',
    "dec_13_2022_8":'PHOTO-2023-01-05-20-05-41-2.jpg',
    "dec_13_2022_9":'PHOTO-2023-01-05-20-05-41.jpg',
    "dec_13_2022_10":'PHOTO-2023-01-05-20-05-42.jpg',
    "dec_17_2022_1":'PHOTO-2023-01-05-20-06-59-2.jpg',
    "dec_17_2022_2":'PHOTO-2023-01-05-20-06-59.jpg',
    }