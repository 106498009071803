import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import maleProfileIcon from '../../Assets/maleProfileIcon.jpeg'
import femaleProfileIcon from '../../Assets/femaleProfileIcon.jpeg'
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function MemberCard({profileUrl,profileImage,type,onPress,isDetailed,...props}) {
  const { t, i18n } = useTranslation();
  let profileIcon=maleProfileIcon
  if(props.gender==='Female'){
    profileIcon=femaleProfileIcon
  }
  if(profileImage){
    profileIcon=profileImage
  }

  return (
    <Col md={isDetailed?12:type==='sm'?2:3} className="project-card" onClick={profileUrl?onPress:()=>{}}>
    <Card className="project-card-view">
      <Card.Img variant="top" src={profileIcon} alt="No Image"/>
      <Card.Body>
        <Card.Title className="mainText" >{props.firstName} {props.middleName} {props.lastName}</Card.Title>
        {props.memberDesignation &&<Card.Title >{t(props.memberDesignation)}</Card.Title>}
       {type!=='sm' && <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>}
        {type!=='sm' && <Card.Text style={{ textAlign: "justify" }}>
        City : <span className="mainText">{props.city}</span>
        </Card.Text>}
        {type!=='sm' && <Card.Text style={{ textAlign: "justify" }}>
        {/* Occupation : <span className="mainText">{personalDetails.occupation}</span> */}
        </Card.Text>}

      </Card.Body>
    </Card>
    </Col>
  );
}
export default MemberCard;
