const bn = {
  "Home":"হোম",
  "About":"বিষয়ের মধ্যে",
  "Members":"সদস্য",
  "Blog":"ব্লগ",
  "Language":"ভাষা",
  "Our purpose at":"हमारा उद्देश्य है",
  "GORAI COMMUNITY":"गोराई समुदाय",
  "is":"में",
  "Here all the Gorai brothers have to be brought together! And build a huge society":"यहाँ सारे गोराई बंधुओं को एक जुट में लाना है ! और एक विशाल सोसाइटी बनाना है!",
  "Help deserving and needful people in":"योग्य और जरूरतमंद लोगों की मदद करना",
  "Education":"शिक्षा",
  "Health":"स्वास्थ्य",
  "Job":"काम",
  "Matrimonial":"वैवाहिक",
  "etc":"आदि में",
  "Recognize best candidates in":"सर्वश्रेष्ठ उम्मीदवारों को पहचानें",
  "Gorai":"गोराई",
  "and":"में ",
  "provide help and support in":"और सहायता प्रदान करें",
  "Medical":"चिकित्सा",
  "Meet people and understand their problems and provide optimal support and suggestion in":"लोगों से मिलें और उनकी समस्याओं को समझें और सर्वोत्तम समर्थन और सुझाव दें",
  "Carrier":"काम",
  "FIND US ON":"हमें यहाँ तलाशें",
  "Feel free to":"",
  "connect":"जुडिये",
  "with us":"हमारे साथ",
  "Login":"প্রবেশ করুন",
  "Register":"নিবন্ধন",
  "Active":"সক্রিয়",
  "Member":"সদস্যরা",
  "General":"",
  "Here are our contributors.":"এখানে আমাদের অবদানকারী."
}

  export default bn