import React from "react";
import Typewriter from "typewriter-effect";

function TypeWriter({wrapperClassName}) {
  return (
    <Typewriter
      options={{
        strings: [
          "GORAI COMMUNITY",
          "GORAI TELI COMMUNITY",
          "TELI SAMAJ",
          "GORAI TELI SAMAJ",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
      wrapperClassName={wrapperClassName}
    />
  );
}

export default TypeWriter;
