export const personalInfo = [
    {
        fieldName: 'profileImage',
        label: 'Upload profile image',
        placeholder: 'Upload profile image',
        type: 'file',
        keyBoardType: 'default',
        validation: [
          {
            required: true,
            errorMessage: 'document is required'
          },
          { regex: '', errorMessage: '' }
        ]
      },
    {
      fieldName: 'firstName',
      label: 'First name',
      placeholder: 'Enter first name',
      type: 'text',
      keyBoardType: 'default',
      validation: [
        {
          required: true,
          errorMessage: 'First name is required'
        },
        { regex: '', errorMessage: 'Invalid name' }
      ]
    },
    {
      fieldName: 'middleName',
      label: 'Middle name',
      placeholder: 'Enter middle name',
      type: 'text',
      keyBoardType: 'default',
      validation: [
        {
          required: false,
          errorMessage: ''
        },
        { regex: '', errorMessage: 'Invalid name' }
      ]
    },
    {
  
      fieldName: 'lastName',
      label: 'Last name',
      placeholder: 'Enter last name',
      type: 'text',
      keyBoardType: 'default',
      validation: [
        {
          required: true,
          errorMessage: 'Last name is required'
        },
        { regex: '', errorMessage: 'Invalid name' }
      ]
    },
    {
  
      fieldName: 'fatherName',
      label: 'Father name',
      placeholder: 'Enter father name',
      type: 'text',
      keyBoardType: 'default',
      validation: [
        {
          required: true,
          errorMessage: 'Father name is required'
        },
        { regex: '', errorMessage: 'Invalid name' }
      ]
    },
    {
      fieldName: 'dob',
      label: 'Date of birth',
      placeholder: 'Enter dob',
      type: 'date',
      keyBoardType: 'default',
      validation: [
        {
          required: true,
          errorMessage: 'Date of birth is required'
        },
        { regex: '', errorMessage: 'Invalid name' }
      ]
    },
    {
      fieldName: 'clan',
      label: 'Clan (Gotra)',
      placeholder: 'Enter Clan',
      type: 'text',
      keyBoardType: 'default',
      validation: [
        {
          required: false,
          errorMessage: ''
        },
        { regex: '', errorMessage: '' }
      ]
    },
  
    {
  
      fieldName: 'gender',
      label: 'Gender',
      placeholder: 'Enter Select',
      type: 'select',
      keyBoardType: '',
      validation: [
        {
          required: true,
          errorMessage: 'Gender is required'
        },
        { regex: '', errorMessage: 'Invalid name' }
      ],
      enum: [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'other', label: 'Other' }
      ]
    },
    {
  
      fieldName: 'bloodGroup',
      label: 'Blood Group',
      placeholder: 'Select Blood Group',
      type: 'select',
      keyBoardType: '',
      validation: [
        {
          required: false,
          errorMessage: ''
        },
        { regex: '', errorMessage: '' }
      ],
      enum: [
        { value: 'B+', label: 'B+' },
        { value: 'B-', label: 'B-' },
        { value: 'O+', label: 'O+' },
        { value: 'O-', label: 'O-' },
        { value: 'AB+', label: 'AB+' },
        { value: 'AB-', label: 'AB-' },

      ]
    },
    {
  
      fieldName: 'memberType',
      label: 'Member Type',
      placeholder: 'Select member type',
      type: 'select',
      keyBoardType: '',
      validation: [
        {
          required: false,
          errorMessage: ''
        },
        { regex: '', errorMessage: '' }
      ],
      enum: [
        { value: 'general', label: 'General' },
        { value: 'active', label: 'Active' },
      ]
    },
  ]

  export const addressDetails=[
    {
        fieldName: 'addressLine1',
        label: 'Address line 1',
        placeholder: 'Enter flat number, house name, area',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: false,
            errorMessage: 'Address Line name is required'
          },
          { regex: '', errorMessage: '' }
        ]
      },
    {
        fieldName: 'addressLine2',
        label: 'Address line 2',
        placeholder: 'Enter locality, road name',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: true,
            errorMessage: ''
          },
          { regex: '', errorMessage: '' }
        ]
      },
      {
        fieldName: 'landMark',
        label: 'Land mark',
        placeholder: 'Enter landmark ',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: true,
            errorMessage: ''
          },
          { regex: '', errorMessage: '' }
        ]
      },
      {
        fieldName: 'state',
        label: 'State',
        placeholder: 'Enter state',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: true,
            errorMessage: 'State is required'
          },
          { regex: '', errorMessage: '' }
        ]
      },
      {
        fieldName: 'city',
        label: 'City',
        placeholder: 'Enter city',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: true,
            errorMessage: 'City is required'
          },
          { regex: '', errorMessage: '' }
        ]
      },
      {
        fieldName: 'pinCode',
        label: 'Pin code',
        placeholder: 'Enter Pin code',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: true,
            errorMessage: 'Pin code is required'
          },
          { regex: '', errorMessage: '' }
        ]
      },
  ]

  export const qualificationDetails = [
    {
  
        fieldName: 'higherEducation',
        label: 'Higher Education',
        placeholder: 'Choose highest education',
        type: 'select',
        keyBoardType: '',
        validation: [
          {
            required: true,
            errorMessage: 'Higher Education is required'
          },
          { regex: '', errorMessage: '' }
        ],
        enum: [
          { value: 'phd', label: 'PHD' },
          { value: 'postGraduation', label: 'Post Graduation' },
          { value: 'graduate', label: 'Graduate' },
          { value: 'diploma', label: 'Diploma' },
          { value: 'hsc', label: 'HSC' },
          { value: 'ssc', label: 'SSC' },
          { value: 'secondarySchooling', label: 'Secondary Schooling' },
          { value: 'primarySchooling', label: 'Primary Schooling' },
          { value: 'prePrimary', label: 'Pre Primary' },
          { value: 'nursery', label: 'Nursery' },
          { value: 'other', label: 'Other' },

        ]
      },
    {
  
        fieldName: 'occupation',
        label: 'Occupation',
        placeholder: 'Choose Occupation',
        type: 'select',
        keyBoardType: '',
        validation: [
          {
            required: false,
            errorMessage: ''
          },
          { regex: '', errorMessage: '' }
        ],
        enum: [
          { value: 'salaried', label: 'Salaried' },
          { value: 'selfEmployed', label: 'Self-Employed' },
          { value: 'homeMaker', label: 'Home Maker' },
          { value: 'student', label: 'Student' },
          { value: 'unemployed', label: 'Unemployed' },
        ]
      },
    {
      fieldName: 'profession',
      label: 'Profession',
      placeholder: 'Enter Profession',
      type: 'text',
      keyBoardType: 'default',
      validation: [
        {
          required: false,
          errorMessage: ''
        },
        { regex: '', errorMessage: '' }
      ]
    },
    {
  
        fieldName: 'piDocumentType',
        label: 'Select proof to submit',
        placeholder: 'Choose Proof Type',
        type: 'select',
        keyBoardType: '',
        validation: [
          {
            required: false,
            errorMessage: ''
          },
          { regex: '', errorMessage: '' }
        ],
        enum: [
          { value: 'aadharCard', label: 'Aadhar Card' },
          { value: 'panCard', label: 'PAN Card' },
          { value: 'drivingLicense', label: 'Driving License' },
          { value: 'passportNo', label: 'Passport No.' },
          { value: 'votersIdentityCard', label: 'Voters Identity card' },
          { value: 'bankPassBook', label: 'Bank Pass Book' },
        ]
      },
    {
  
      fieldName: 'piDocumentId',
      label: 'Unique No of the proof submitted',
      placeholder: 'Enter Unique No of the proof submitted',
      type: 'text',
      keyBoardType: 'default',
      validation: [
        {
          required: true,
          errorMessage: 'Unique No is required'
        },
        { regex: '', errorMessage: '' }
      ]
    },
    {
      fieldName: 'piDocument',
      label: 'Upload the proof document(IMAGE / PDF file only)',
      placeholder: 'Upload the proof document(IMAGE / PDF file only)',
      type: 'file',
      keyBoardType: 'default',
      validation: [
        {
          required: true,
          errorMessage: 'document is required'
        },
        { regex: '', errorMessage: '' }
      ]
    },
 
  
  ]

  export const registrationForm=[
    {
    step:1,
    formName:'personalInfo',
    label:"Personal Information",
    heading:"",
    subheading:"",
    config:personalInfo
  },
    {
    step:2,
    formName:'addressDetails',
    label:"Address Details",
    heading:"",
    subheading:"",
    config:addressDetails
  },
    {
    step:3,
    formName:'qualificationDetails',
    label:"Qualification Details",
    heading:"",
    subheading:"",
    config:qualificationDetails
  }
]