const en= {
    "Home":"Home",
    "About":"About",
    "Members":"Members",
    "Blog":"Blog",
    "Language":"Language",
    "Our purpose at":"Our purpose at",
    "GORAI COMMUNITY":"GORAI COMMUNITY",
    "is":"is",
    "Here all the Gorai brothers have to be brought together! And build a huge society":"Here all the Gorai brothers have to be brought together! And build a huge society",
    "Help deserving and needful people in":"Help deserving and needful people in",
    "Education":"Education",
    "Health":"Health",
    "Job":"Job",
    "Matrimonial":"Matrimonial",
    "etc":"etc",
    "Recognize best candidates in":"Recognize best candidates in",
    "Gorai":"Gorai",
    "and":"and",
    "provide help and support in":"provide help and support in",
    "Medical":"Medical",
    "Meet people and understand their problems and provide optimal support and suggestion in":"Meet people and understand their problems and provide optimal support and suggestion in",
    "Carrier":"Carrier",
    "FIND US ON":"FIND US ON",
    "Feel free to":"Feel free to",
    "connect":"connect",
    "with us":"with us",
    "Register":"Register",
    "Login":"Login",
    "Active":"Active",
    "Member":"Member",
    "Here are a few helping hands.":"Here are a few helping hands.",
    "General":"General",
    "Here are our contributors.":"Here are our contributors.",
    "Activity":"Activity",
    "Hindi":"Hindi",
    "English":"English",
    "Designed and Developed by Ranvir Gorai":"Designed and Developed by Ranvir Gorai",
    "Copyright":"Copyright",
    "Our":"Our",
    "Work":"Work",
    "Our contribution through out the year":"Our contribution through out the year",
    "Admirable":"Admirable",
    "Secretary":"Secretary",
    "President":"President",
    "Treasure":"Treasure",
    "Social media in charge":"Social media in charge"
  }

  export default en