import React, { useState } from "react";
import {  Form, } from "react-bootstrap";
import Upload from "../../Containers/Upload";


function DynamicForm({ formConfig, isShowUpload, setShowUpload, formData }) {
    let formFiledContent = null

    function onTextChange(e) {
        formConfig.onChange({ [e.target.name]: e.target.value, formName: formConfig.formName })
    }

    switch (formConfig.type) {
        case 'date':
        case 'text':
            formFiledContent =
                <Form.Control type={formConfig.type}
                    placeholder={formConfig.placeholder}
                    disabled={formConfig.disabled}
                    defaultValue={formConfig.defaultValue}
                    value={formData[formConfig.fieldName]}
                    name={formConfig.fieldName}
                    onChange={onTextChange}
                    readOnly={formConfig.readOnly}
                />
            break;
        case 'select':
        case 'dropDown':
            formFiledContent = <Form.Select
                placeholder={formConfig.placeholder}
                disabled={formConfig.disabled}
                defaultValue={formConfig.defaultValue}
                value={formData[formConfig.fieldName]}
                name={formConfig.fieldName}
                onChange={onTextChange}
                readOnly={formConfig.readOnly}

            >
                {formConfig.enum.map(item => <option key={item.value}>{item.label}</option>)}
            </Form.Select>
            break;

        case 'checkbox':
        case 'radio':
        case 'switch':
            formFiledContent = <Form.Check
                type={formConfig.type}
                placeholder={formConfig.placeholder}
                disabled={formConfig.disabled}
                defaultValue={formConfig.defaultValue}
                value={formData[formConfig.fieldName]}
                name={formConfig.fieldName}
                onChange={onTextChange}
                readOnly={formConfig.readOnly}
            />
            break;
        case 'file':
            formFiledContent = <Upload
                getFilesPath={onTextChange}
                single={true}
                images={formData[formConfig.fieldName]}
                uploadOnSelect={false}
                capture={true}
                isShowUpload={isShowUpload || true}
                setShowUpload={setShowUpload}
            />
            break;
        default: {
            formFiledContent = null
        }
    }

    return <Form.Group className="mb-3" controlId="personalInfo" key={formConfig.fieldName}>
        <Form.Label>{formConfig.label}</Form.Label>
        {formFiledContent}
        {formConfig.errorText ? <Form.Text className="text-error">
            {formConfig.errorText}
        </Form.Text> : null}
    </Form.Group>


}

export default DynamicForm;
