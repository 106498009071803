import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useParams } from "react-router-dom";
import DynamicForm from "../../Components/DynamicForm";
import Particle from "../../Components/Particle";
import { END_POINT_MEMBERS } from "../../Config";
import { resumeFelids } from "../../Constants/resumeBuilder";


function Register() {
  const [step, setStep] = useState(0);
  const [formData, setFromData] = useState({})
  const [isShowUpload,setShowUpload]=useState(false)
  const { profileUrl } = useParams();
  
  function handleSubmit() {
    if (step < resumeFelids.length - 1) {
      setStep(step + 1)
    }else{
      axios.post(END_POINT_MEMBERS,formData)
    }
  }

  async function makeApiCall(){
    const response = await axios.get(END_POINT_MEMBERS+'/'+profileUrl);
    setFromData(response.data.data)
  }

  useEffect(() => {
    if(profileUrl){
    makeApiCall()
    }
  }, [profileUrl])

  function handlePrevious() {
      setStep(step -1)
  }
  function handleUpload(e){
    setShowUpload(!isShowUpload)
    e.preventDefault()
  }

  function onChange(data,formName){
    let formDataT={...formData,...data}
    setFromData(formDataT)
  }
  return (
    <section>
      <Container fluid className="home-about-section" id="home">
        <Particle />
        <Container className="home-content ">

          <Row className="justify-content-md-center" >
            <Col md={6} >
              <Card bg={'secondary'} className="justify-content-md-center">
                <Card.Header>Resume builder - {resumeFelids[step].label}</Card.Header>
                <Card.Body>
                  {/* <Card.Title>{resumeFelids[step].label}</Card.Title> */}
                  <Form>
                    {resumeFelids[step].config.map((item) => {
                      return <DynamicForm
                      key={item.fieldName}
                        formConfig={{...item,onChange,formName:resumeFelids[step].formName}}
                        isShowUpload={isShowUpload}
                        setShowUpload={handleUpload}
                        formData={formData}
                         />
                    })}
                    <div className="loginButtonWrapper">
                    <Button variant="secondary" className="pull-right1" onClick={handlePrevious} disabled={step === 0} >
                        Previous
                      </Button>
                      <Button variant="primary" className="pull-right" onClick={handleSubmit} >
                        {step === resumeFelids.length-1?'Submit':'Next'}
                      </Button>
                     
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>

    </section>
  );
}

export default Register;
