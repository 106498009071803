export const personalInfo = [
 
    {
      fieldName: 'firstName',
      label: 'First name',
      placeholder: 'First name',
      type: 'text',
      keyBoardType: 'default',
      validation: [
        {
          required: true,
          errorMessage: 'First name is required'
        },
        { regex: '', errorMessage: 'Invalid name' }
      ]
    },
    {
      fieldName: 'middleName',
      label: 'Middle name',
      placeholder: 'Middle name',
      type: 'text',
      keyBoardType: 'default',
      validation: [
        {
          required: false,
          errorMessage: ''
        },
        { regex: '', errorMessage: 'Invalid name' }
      ]
    },
    {
  
      fieldName: 'lastName',
      label: 'Last name',
      placeholder: 'Last name',
      type: 'text',
      keyBoardType: 'default',
      validation: [
        {
          required: true,
          errorMessage: 'Last name is required'
        },
        { regex: '', errorMessage: 'Invalid name' }
      ]
    },
  
    {
      fieldName: 'dob',
      label: 'Date of birth',
      placeholder: 'Eg: 01 Jan 2000',
      type: 'date',
      keyBoardType: 'default',
      validation: [
        {
          required: true,
          errorMessage: 'Date of birth is required'
        },
        { regex: '', errorMessage: 'Invalid name' }
      ]
    },
    {
      fieldName: 'birthTime',
      label: 'Birth Time',
      placeholder: 'Eg: 10:00 AM',
      type: 'text',
      keyBoardType: 'default',
      validation: [
        {
          required: true,
          errorMessage: 'Date of birth is required'
        },
        { regex: '', errorMessage: 'Invalid name' }
      ]
    },
    {
      fieldName: 'clan',
      label: 'Clan (Gotra)',
      placeholder: 'Clan',
      type: 'text',
      keyBoardType: 'default',
      validation: [
        {
          required: false,
          errorMessage: ''
        },
        { regex: '', errorMessage: '' }
      ]
    },
    {
        fieldName: 'Zodiac Signs (Rashi)',
        label: 'Zodiac Signs (Rashi)',
        placeholder: 'Zodiac Signs (Rashi)',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: false,
            errorMessage: ''
          },
          { regex: '', errorMessage: '' }
        ]
      },
    {
      fieldName: 'Cast',
      label: 'Cast',
      placeholder: 'Cast',
      type: 'text',
      keyBoardType: 'default',
      validation: [
        {
          required: false,
          errorMessage: ''
        },
        { regex: '', errorMessage: '' }
      ]
    },
 
    {
  
      fieldName: 'gender',
      label: 'Gender',
      placeholder: 'Select',
      type: 'select',
      keyBoardType: '',
      validation: [
        {
          required: true,
          errorMessage: 'Gender is required'
        },
        { regex: '', errorMessage: 'Invalid name' }
      ],
      enum: [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' },
        { value: 'other', label: 'Other' }
      ]
    },
    {
  
      fieldName: 'bloodGroup',
      label: 'Blood Group',
      placeholder: 'Select Blood Group',
      type: 'select',
      keyBoardType: '',
      validation: [
        {
          required: false,
          errorMessage: ''
        },
        { regex: '', errorMessage: '' }
      ],
      enum: [
        { value: 'B+', label: 'B+' },
        { value: 'B-', label: 'B-' },
        { value: 'O+', label: 'O+' },
        { value: 'O-', label: 'O-' },
        { value: 'AB+', label: 'AB+' },
        { value: 'AB-', label: 'AB-' },

      ]
    },
    {
        fieldName: 'Height',
        label: 'Height',
        placeholder: 'Height',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: false,
            errorMessage: ''
          },
          { regex: '', errorMessage: '' }
        ]
      },
    
    {
      fieldName: 'profileImage',
      label: 'Upload profile image',
      placeholder: 'Upload profile image',
      type: 'file',
      keyBoardType: 'default',
      validation: [
        {
          required: true,
          errorMessage: 'document is required'
        },
        { regex: '', errorMessage: '' }
      ]
    },
    
  ]
  export const familyDetails=[
    {
        fieldName: 'Father name',
        label: 'Father name',
        placeholder: 'Father name',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: true,
            errorMessage: 'Father name is required'
          },
          { regex: '', errorMessage: '' }
        ]
      },
      {
        fieldName: 'Father Occupation',
        label: 'Father Occupation',
        placeholder: 'Father Occupation',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: false,
            errorMessage: ''
          },
          { regex: '', errorMessage: '' }
        ]
      },
      {
        fieldName: 'Mother name',
        label: 'Mother name',
        placeholder: 'Mother name',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: true,
            errorMessage: 'Mother name is required'
          },
          { regex: '', errorMessage: '' }
        ]
      },
      {
        fieldName: 'Mother Occupation',
        label: 'Mother Occupation',
        placeholder: 'Mother Occupation',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: false,
            errorMessage: ''
          },
          { regex: '', errorMessage: '' }
        ]
      },
      {
        fieldName: 'Siblings',
        label: 'Siblings',
        placeholder: 'Number of siblings',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: false,
            errorMessage: ''
          },
          { regex: '', errorMessage: '' }
        ]
      },
      {
        fieldName: 'Sibling Married',
        label: 'Sibling Married',
        placeholder: 'Number of siblings got married',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: false,
            errorMessage: ''
          },
          { regex: '', errorMessage: '' }
        ]
      },
      
  ]

  export const addressDetails=[
    {
        fieldName: 'Contact Number',
        label: 'Contact Number',
        placeholder: 'Contact Number',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: false,
            errorMessage: 'Contact Number'
          },
          { regex: '', errorMessage: '' }
        ]
      },
    {
        fieldName: 'addressLine1',
        label: 'Address',
        placeholder: 'flat number, house name, area',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: false,
            errorMessage: 'Address Line 1 is required'
          },
          { regex: '', errorMessage: '' }
        ]
      },
      {
        fieldName: 'state',
        label: 'State',
        placeholder: 'state',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: true,
            errorMessage: 'State is required'
          },
          { regex: '', errorMessage: '' }
        ]
      },
      {
        fieldName: 'city',
        label: 'City',
        placeholder: 'city',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: true,
            errorMessage: 'City is required'
          },
          { regex: '', errorMessage: '' }
        ]
      },
      {
        fieldName: 'pinCode',
        label: 'Pin code',
        placeholder: 'Pin code',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: true,
            errorMessage: 'Pin code is required'
          },
          { regex: '', errorMessage: '' }
        ]
      },
  ]

  export const qualificationDetails = [
    {
        fieldName: 'higherEducation',
        label: 'Highest Education',
        placeholder: 'Highest education',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: true,
            errorMessage: 'Highest Education is required'
          },
          { regex: '', errorMessage: '' }
        ]
      },
      {
        fieldName: 'Organization',
        label: 'Organization',
        placeholder: 'Organization',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: true,
            errorMessage: 'Organization is required'
          },
          { regex: '', errorMessage: '' }
        ]
      },
      {
        fieldName: 'Designation',
        label: 'Designation',
        placeholder: 'Designation',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: true,
            errorMessage: 'Designation is required'
          },
          { regex: '', errorMessage: '' }
        ]
      },
      {
        fieldName: 'Job Location',
        label: 'Job Location',
        placeholder: 'Job Location',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: true,
            errorMessage: 'Job Location is required'
          },
          { regex: '', errorMessage: '' }
        ]
      },
      {
        fieldName: 'Compensation',
        label: 'Compensation',
        placeholder: 'Compensation',
        type: 'text',
        keyBoardType: 'default',
        validation: [
          {
            required: false,
            errorMessage: ''
          },
          { regex: '', errorMessage: '' }
        ]
      },
  
  ]

  export const resumeFelids=[
    {
    step:1,
    formName:'personalInfo',
    label:"Personal Information",
    heading:"",
    subheading:"",
    config:personalInfo
  },
    {
    step:2,
    formName:'qualificationDetails',
    label:"Qualification Details",
    heading:"",
    subheading:"",
    config:qualificationDetails
  },
  {
    step:2,
    formName:'familyDetails',
    label:"Family Details",
    heading:"",
    subheading:"",
    config:familyDetails
  },
  {
    step:2,
    formName:'addressDetails',
    label:"Contact Details",
    heading:"",
    subheading:"",
    config:addressDetails
  },
]