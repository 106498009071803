import _MG_8888 from "./_MG_8888.jpg"
import _MG_8896 from "./_MG_8896.jpg"
import _MG_8937 from "./_MG_8937.jpg"
import _MG_8939 from "./_MG_8939.jpg"
import _MG_8952 from "./_MG_8952.jpg"
import _MG_8984 from "./_MG_8984.jpg"
import _MG_8997 from "./_MG_8997.jpg"
import _MG_9036 from "./_MG_9036.jpg"
import _MG_9054 from "./_MG_9054.jpg"
import _MG_9180 from "./_MG_9180.jpg"
import _MG_9183 from "./_MG_9183.jpg"
import _MG_9197 from "./_MG_9197.jpg"
import _MG_9203 from "./_MG_9203.jpg"
import _MG_9210 from "./_MG_9210.jpg"
import _MG_9218 from "./_MG_9218.jpg"
import _MG_9225 from "./_MG_9225.jpg"
import _MG_9287 from "./_MG_9287.jpg"
import _MG_9358 from "./_MG_9358.jpg"
import IMG_1951 from "./IMG_1951.jpg"
import IMG_1968 from "./IMG_1968.jpg"
import IMG_1987 from "./IMG_1987.jpg"
import IMG_2011 from "./IMG_2011.jpg"
import webSiteLaunch from "./webSiteLaunch.jpg"

const picnic2023SlideImages = {
    _MG_8888,
    _MG_8896,
    _MG_8937,
    _MG_8939,
    _MG_8952,
    _MG_8984,
    _MG_8997,
    _MG_9036,
    _MG_9054,
    _MG_9180,
    _MG_9183,
    _MG_9197,
    _MG_9203,
    _MG_9210,
    _MG_9218,
    _MG_9225,
    _MG_9287,
    _MG_9358,
    IMG_1951,
    IMG_1968,
    IMG_1987,
    IMG_2011,
    webSiteLaunch,
}

export default picnic2023SlideImages