import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import logo from "../Assets/logo.png";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { CgGitFork } from "react-icons/cg";
import { ImBlog, ImUserCheck } from "react-icons/im";
import {
  AiFillStar,
  AiOutlineHome,
  AiOutlineFundProjectionScreen,
  AiOutlineUser,
  AiOutlineUserSwitch,
  AiOutlineMessage,
  AiOutlineUserAdd
} from "react-icons/ai";

import { CgFileDocument } from "react-icons/cg";

import { useTranslation } from 'react-i18next';
import { NavDropdown } from "react-bootstrap";

function NavBar({ isLaunched }) {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const { t, i18n } = useTranslation();

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  function handleLanguage(language) {
    i18n.changeLanguage(language)
  }

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container>
        <Navbar.Brand href="/" className="d-flex">
          <img src={logo} className="img-fluid logo" alt="brand" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => {
            updateExpanded(expand ? false : "expanded");
          }}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto" defaultActiveKey="#home">
            <Nav.Item >
              <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                <AiOutlineHome style={{ marginBottom: "2px" }} /> {t('Home')}
              </Nav.Link>
            </Nav.Item>

            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/about"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineMessage style={{ marginBottom: "2px" }} /> {t('About')}
              </Nav.Link>
            </Nav.Item> */}

            {isLaunched && <Nav.Item>
              <Nav.Link
                as={Link}
                to="/members"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineUserSwitch
                  style={{ marginBottom: "2px" }}
                />{" "}
                {t('Members')}
              </Nav.Link>
            </Nav.Item>}

            {isLaunched && <Nav.Item>
              <Nav.Link
                as={Link}
                to="/activity"
                onClick={() => updateExpanded(false)}
              >
                <ImBlog style={{ marginBottom: "2px", marginRight: "4px" }} />{t('Activity')}
              </Nav.Link>
            </Nav.Item>}

            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/achiever"
                onClick={() => updateExpanded(false)}
              >
                <ImUserCheck style={{ marginBottom: "2px", marginRight: "4px" }} />{t('Admirable')}
              </Nav.Link>
            </Nav.Item> */}

            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/login"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineUser
                  style={{ marginBottom: "2px" }}
                />{" "}
                {t('Login')}
              </Nav.Link>
            </Nav.Item>
            */}
             {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/resume-builder"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineUserAdd
                  style={{ marginBottom: "2px" }}
                />{" "}
                {t('Resume builder')}
              </Nav.Link>
            </Nav.Item>  */}
            {/* <Nav.Item>
              <Nav.Link
                as={Link}
                to="/register"
                onClick={() => updateExpanded(false)}
              >
                <AiOutlineUserAdd
                  style={{ marginBottom: "2px" }}
                />{" "}
                {t('Register')}
              </Nav.Link>
            </Nav.Item>  */}
            <NavDropdown title={t('Language')} id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1" onClick={() => handleLanguage("en")}>{t('English')}</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2" onClick={() => handleLanguage("hi")}>{t('Hindi')}</NavDropdown.Item>
              {/* <NavDropdown.Item href="#action/3.2" onClick={() => handleLanguage("bn")}>বাংলা</NavDropdown.Item> */}
            </NavDropdown>


            {/* <Nav.Item className="fork-btn">
              <Button
                href="https://github.com/ranvirgorai"
                target="_blank"
                className="fork-btn-inner"
              >
                <CgGitFork style={{ fontSize: "1.2em" }} />{" "}
                <AiFillStar style={{ fontSize: "1.1em" }} />
              </Button>
            </Nav.Item> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
