import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Main from "./main";
import "./styles.css";
class Upload extends Component {
  toggleUpload(e) {
    this.props.setShowUpload()
    e.preventDefault()
    
  }
  render() {
    return (
      <div className="App">
        <div className="Card">
          {this.props.isShowUpload ? <Main {...this.props} /> : null}
          {/* <Button type="secondary" onClick={this.toggleUpload}>{this.props.isShowUpload ? 'Hide Upload' : 'Show Upload'}</Button> */}
        </div>
      </div>
    );
  }
}

export default Upload;
