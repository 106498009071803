import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/logo.png";
import Tilt from "react-parallax-tilt";
import { FaFacebook } from "react-icons/fa";

function ComingSoon() {
    return (
        <Container fluid className="home-about-section" id="about">

            <Container>
                <Row>
                    <Col md={12} className="home-about-description">
                        <h1 style={{ fontSize: "2.6em" }}>
                            Coming soon <span className="mainText"> GORAI COMMUNITY </span> Official Website.
                        </h1>
                        <Tilt>
                            <img src={myImg} className="img-fluid" alt="avatar" />
                        </Tilt>
                        {/* <p className="home-about-body">
                            I fell in love with programming and I have at least learnt
                            something, I think… 🤷‍♂️
                            <br />
                            <br />I am fluent in classics like
                            <i>
                                <b className="mainText"> C++, Javascript and Python. </b>
                            </i>
                            <br />
                            <br />
                            My field of Interest's are building new &nbsp;
                            <i>
                                <b className="mainText">Web Technologies and Products </b> and
                                also in areas related to{" "}
                                <b className="mainText">
                                    Deep Learning and Natural Launguage Processing.
                                </b>
                            </i>
                            <br />
                            <br />
                            Whenever possible, I also apply my passion for developing products
                            with <b className="mainText">Node.js</b> and
                            <i>
                                <b className="mainText">
                                    {" "}
                                    Modern Javascript Library and Frameworks
                                </b>
                            </i>
                            &nbsp; like
                            <i>
                                <b className="mainText"> React.js and Next.js</b>
                            </i>
                        </p> */}
                    </Col>
                    {/* <Col md={4} className="myAvtar">
                       
                    </Col> */}
                </Row>
                <Row>
                    <Col md={12} className="home-about-social">
                        <h1>FIND US ON</h1>
                        <p>
                            Feel free to <span className="mainText">connect </span>with us
                        </p>
                        <ul className="home-about-social-links">
                            <li className="social-icons">
                                <a
                                    href="https://www.facebook.com/groups/1512489479001705"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="icon-colour  home-social-icons"
                                >
                                    <FaFacebook />
                                </a>
                            </li>

                        </ul>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}
export default ComingSoon;
