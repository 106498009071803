const hi = {
  "Home": "मुख पृष्ठ",
  "About": "विषय में",
  "Members": "सदस्य",
  "Blog": "ब्लॉग",
  "Language": "भाषा",
  "Our purpose at": "हमारा उद्देश्य है",
  "GORAI COMMUNITY": "गोराई समुदाय",
  "is": "में",
  "Here all the Gorai brothers have to be brought together! And build a huge society": "यहाँ सारे गोराई बंधुओं को एक जुट में लाना है ! और एक विशाल सोसाइटी बनाना है!",
  "Help deserving and needful people in": "योग्य और जरूरतमंद लोगों की मदद करना",
  "Education": "शिक्षा",
  "Health": "स्वास्थ्य",
  "Job": "काम",
  "Matrimonial": "वैवाहिक",
  "etc": "आदि में",
  "Recognize best candidates in": "सर्वश्रेष्ठ उम्मीदवारों को पहचानें",
  "Gorai": "गोराई",
  "and": "में ",
  "provide help and support in": "और सहायता प्रदान करें",
  "Medical": "चिकित्सा",
  "Meet people and understand their problems and provide optimal support and suggestion in": "लोगों से मिलें और उनकी समस्याओं को समझें और सर्वोत्तम समर्थन और सुझाव दें",
  "Carrier": "काम",
  "FIND US ON": "हमें यहाँ तलाशें",
  "Feel free to": "",
  "connect": "जुडिये",
  "with us": "हमारे साथ",
  "Register": "रजिस्टर करें",
  "Login": "लॉग इन",
  "Active": "सक्रिय",
  "Member": "सदस्य",
  "Here are a few helping hands.": "हम आपकी मदद करने के लिए तैयार हैं",
  "General": "",
  "Here are our contributors.": "यहां हमारे योगदानकर्ता हैं।",
  "Our": "Our",
  "Activity": "सक्रियता",
  "Hindi": "हिन्दी",
  "English": "इंगलिश",
  "Designed and Developed by Ranvir Gorai": "यह वेब साइट रणवीर गोराई द्वारा डिज़ाइन और डेवलप किया गया है ",
  "Copyright": "कॉपीराइट",
  "Our": "हमारा",
  "Work": "कार्य",
  "Our contribution through out the year": "पूरे साल के दौरान हमारा सहयोग",
  "Admirable":"सराहनीय",
  "Secretary":"सचिव",
  "President":"अध्यक्ष",
  "Treasure" :"कोषाध्यक्ष",
  "Social media in charge":"सोशल मीडिया प्रभारी"
}
export default hi