import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import Particle from "../../Components/Particle.js";
import FeedCard from "./FeedCard";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { END_POINT_ACTIVITY } from "../../Config/index.js";
import Preloader from "../../Components/Pre";


function Activity() {
  const [filteredFeedData, setFilteredFeedData] = useState([])
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)

  function onClick(data) {
    navigate(`/activity/${data.feedId}`, { state: { id: data.feedId } });
  }

  async function makeApiCall(){
    setLoading(true)
    const response = await axios.get(END_POINT_ACTIVITY);
    setFilteredFeedData(response.data.data)
    setLoading(false)
  }

  useEffect(() => {
    makeApiCall()
  }, [])

  
  
  
  return (
    <Container fluid className="project-section">
      <Particle />
      <Preloader load={loading} />
      <Container>
        <h1 className="project-heading">
          {t('Our')} <strong className="mainText"> {t('Activity')} </strong>
        </h1>
        <p style={{ color: "white" }}>
          {t("Our contribution through out the year")}
        </p>
        <Row
          style={{ justifyContent: "center", paddingBottom: "10px" }}
        >
          {/* <InfiniteScroll
            dataLength={feeds.length}
            next={() => {
              // console.log("call next")
              // setFilteredFeedData([...feeds])
            }}
            // height={600}
            style={{
              justifyContent: "center",
              paddingBottom: "10px",
              overflow: 'auto',
              display: 'flex',
              flexWrap: 'wrap'
            }}
            inverse={false} //
            hasMore={true}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          > */}
            {filteredFeedData.map((item, index) => {
              return <FeedCard
                onClick={() => onClick(item)}
                key={item.feedId}
                media={item.media}
                feedId={item.feedId}
                approved={item.approved}
                archived={item.archived}
                created_at={item.created_at}
                date={item.date}
                deleted={item.deleted}
                feedMessage={item.feedMessage}
                headline={item.headline}
                views={item.views}
                location={item.location}
                members={item.members}
                updated_at={item.updated_at}
                userId={item.userId}
              />
            })}
          {/* </InfiniteScroll> */}
        </Row>
      </Container>
    </Container>
  );
}

export default Activity;
