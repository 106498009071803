import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import Particle from "../../Components/Particle.js";
import MemberCard from "./MemberCard";
import memberImage from '../../Assets/Members'
import { useParams } from "react-router-dom";
import memberConfigData from './mock'
import _find from 'lodash/find'
import axios from "axios";
import { API_BASE, END_POINT_MEMBERS } from "../../Config/index.js";
import Preloader from "../../Components/Pre";



function MemberDetail() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [item,setItem]=useState({})
  const [loading, setLoading] = useState(false)

  async function makeApiCall(){
    setLoading(true)
    const response = await axios.get(END_POINT_MEMBERS+'/'+id);
    setItem(response.data.data || {})
    setLoading(false)
  }

  useEffect(() => {
    makeApiCall()
  }, [])

  return (
    <Container fluid className="project-section">
      <Preloader load={loading} />
      <Particle />
      <Container>
        <h1 className="project-heading">
           <strong className="mainText"> {item.firstName} {item.middleName} {item.lastName}</strong>
        </h1>
        <p style={{ color: "white" }}>
          {item.city}
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          
            <MemberCard
              key={item.id}
              type={"lg"}
              isDetailed
              userId={item.userId}
              profileUrl={item.profileUrl}
              memberId={item.memberId}
              email={item.email}
              mobile={item.mobile}
              password={item.password}
              level={item.level}
              isValidatedMobile={item.isValidatedMobile}
              isValidatedEmail={item.isValidatedEmail}
              firstTime={item.firstTime}
              memberType={item.memberType}
              profileImage={API_BASE+item?.profileImage?.path}
              firstName={item.firstName}
              middleName={item.middleName}
              lastName={item.lastName}
              fatherName={item.fatherName}
              dob={item.dob}
              gender={item.gender}
              addressLine1={item.addressLine1}
              addressLine2={item.addressLine2}
              landMark={item.landMark}
              state={item.state}
              city={item.city}
              pinCode={item.pinCode}
              higherEducation={item.higherEducation}
              occupation={item.occupation}
              profession={item.profession}
              piDocumentType={item.piDocumentType}
              piDocumentId={item.piDocumentId}
              piDocumentPath={item.piDocumentPath}
              bloodGroup={item.bloodGroup}
              clan={item.clan}
            />
          

        </Row>
      </Container>
    </Container>
  );
}

export default MemberDetail;
