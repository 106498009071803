import picnic2k20 from './picnic2k20.jpeg'
import picnic2k20Art from './picnic2k20Art.jpeg'
import sadsyataAbhiyan from './sadsyataAbhiyan.jpeg'
import memberMeet from './memberMeet.jpg'
import renualDay from './renualDay.jpg'
import bloodDonationCamp from './bloodDonationCamp.jpg'
import picnic2023SlideImages from './picnic2023Slides'
import indDayImage from './ind-day-2024/15th-aug-2024.jpg'

const sliderConfig = [

  {
    id: "indDayImage",
    image: indDayImage,
    heading: "15 अगस्त के पावन अवसर पर हम सभी ने एकत्रित होकर हर्ष और उल्लास के साथ मिलकर तिरंगा झंडा फहराया।",
    subheading: "देशभक्ति के इस महापर्व पर हमने राष्ट्र की एकता, अखंडता और स्वतंत्रता का जश्न मनाया और शहीदों को नमन किया, जिन्होंने हमें यह आजादी दिलाई। इस गर्व के क्षण में हर एक व्यक्ति के दिल में देशप्रेम की भावना प्रबल हो उठी और हम सबने मिलकर भारत माता की जयघोष के साथ राष्ट्रीय ध्वज का मान बढ़ाया।",
    Date: "15/08/2024"
  },
  {
    id: "_MG_8888",
    image: picnic2023SlideImages._MG_8888,
    heading: "दीप प्रज्वलित",
    subheading: "दीप प्रज्वलित",
    Date: "29/01/2023"
  },
  {
    id: "_MG_8896",
    image: picnic2023SlideImages._MG_8896,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "_MG_8937",
    image: picnic2023SlideImages._MG_8937,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "_MG_8939",
    image: picnic2023SlideImages._MG_8939,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "_MG_8952",
    image: picnic2023SlideImages._MG_8952,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "_MG_8984",
    image: picnic2023SlideImages._MG_8984,
    heading: "वेबसाइट लॉंच कार्यक्रम",
    subheading: "वेबसाइट लॉंच कार्यक्रम",
    Date: "29/01/2023"
  },
  {
    id: "_MG_8997",
    image: picnic2023SlideImages._MG_8997,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "_MG_9036",
    image: picnic2023SlideImages._MG_9036,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "_MG_9054",
    image: picnic2023SlideImages._MG_9054,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "_MG_9180",
    image: picnic2023SlideImages._MG_9180,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "_MG_9183",
    image: picnic2023SlideImages._MG_9183,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "_MG_9197",
    image: picnic2023SlideImages._MG_9197,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "_MG_9203",
    image: picnic2023SlideImages._MG_9203,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "_MG_9210",
    image: picnic2023SlideImages._MG_9210,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "_MG_9218",
    image: picnic2023SlideImages._MG_9218,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "_MG_9225",
    image: picnic2023SlideImages._MG_9225,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "_MG_9287",
    image: picnic2023SlideImages._MG_9287,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "_MG_9358",
    image: picnic2023SlideImages._MG_9358,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "IMG_1951",
    image: picnic2023SlideImages.IMG_1951,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "IMG_1968",
    image: picnic2023SlideImages.IMG_1968,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "IMG_1987",
    image: picnic2023SlideImages.IMG_1987,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "IMG_2011",
    image: picnic2023SlideImages.IMG_2011,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: "webSiteLaunch2",
    image: picnic2023SlideImages.webSiteLaunch,
    heading: "",
    subheading: "",
    Date: "29/01/2023"
  },
  {
    id: 1,
    image: memberMeet,
    heading: "केंद्रीय सदस्यता सम्मेलन किया गया, सतनाला डोभो डैम में।",
    subheading: "केंद्रीय सदस्यता सम्मेलन किया गया, सतनाला डोभो डैम में।",
    Date: "11/12/2022"
  },
  {
    id: 2,
    image: renualDay,
    heading: "गोराई (तेली) कुलु समाज कल्याण समिति के स्थापना दिवस के शुभ अवसर पर बराद्वारी वृद्धा आश्रम में 10000 रूपया का सहयोग किया गया।",
    subheading: "गोराई (तेली) कुलु समाज कल्याण समिति के स्थापना दिवस के शुभ अवसर पर बराद्वारी वृद्धा आश्रम में 10000 रूपया का सहयोग किया गया।",
    Date: "02/12/2022"
  },
  {
    id: 3,
    image: bloodDonationCamp,
    heading: "सोनारी सीपी क्लब में ब्लड डोनेशन कैम्प",
    subheading: "सोनारी सीपी क्लब में ब्लड डोनेशन कैम्प",
    Date: "02/12/2022"
  },
  {
    id: 4,
    image: picnic2k20,
    heading: "gorai teli samaj varshik van bhoj milan samaroh",
    subheading: "दिनांक 01/01/2020 स्थान स्टील क्लब हाउस गंधक रोड साकची जमशेदपुर झारखंड में करीब 7000 के आसपास गोराई समाज द्वारा आयोजित पिकनिक में लोग शामिल हुए प्रोग्राम में बच्चे लोग के लिए ड्राइंग कंपटीशन एवं डांस प्रोग्राम और महिलाओं के लिए म्यूजिकल चेयर का आयोजन किया है और अव्वल आने वालों को प्राइस भी वितरण किया गया साथ में बादल पाल का रंगारंग कार्यक्रम आयोजित किया गया एवं मुख्य अतिथि के तौर पर स्थानीय सांसद एवं विधायक भी शामिल हुए",
    Date: "01/01/2020"
  },
  {
    id: 5,
    image: picnic2k20Art,
    heading: "Yearly meetup (picnic)",
    subheading: "Drawing Competition Among children",
    Date: "01/01/2020"
  },
  {
    id: 6,
    image: sadsyataAbhiyan,
    heading: "Membership Campings",
    subheading: "Gorai community membership",
    Date: "01/01/2021"
  },
]

export default sliderConfig