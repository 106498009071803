const memberConfigData = [
  {
    userId: 'arunGorai123',
    profileUrl: "arun-gorai",
    memberId: 'arunGorai123',
    email: '',
    mobile: '',
    password: '',
    level: 1,
    isValidatedMobile: false,
    isValidatedEmail: false,
    firstTime: true,
    memberType: '1',
    profileImage: {
      "path": "/uploads/arunGorai.jpg",
      "thumbnail": "/uploads/placeholder-user.png",
      "placeholder": "/uploads/placeholder-user.png"
    },
    firstName: 'Arun',
    middleName: '',
    lastName: 'Gorai',
    fatherName: '',
    dob: '',
    gender: 'male',
    addressLine1: '',
    addressLine2: 'Jamshedpur',
    landMark: '',
    state: 'Jharkhand',
    city: 'Jamshedpur',
    pinCode: '',
    higherEducation: '',
    occupation: '',
    profession: '',
    piDocumentType: 'piDocumentType',
    piDocumentId: 'piDocumentId',
    piDocumentPath: 'piDocumentPath',
    bloodGroup: '',
    clan: '',
  },
  {
    userId: 'sudeshGorai123',
    profileUrl: "sudhesh-gorai",
    memberId: 'sudheshGorai123',
    email: '',
    mobile: '',
    password: '',
    level: 1,
    isValidatedMobile: false,
    isValidatedEmail: false,
    firstTime: true,
    memberType: '1',
    profileImage: {
      "path": "/uploads/sudeshGorai.jpg",
      "thumbnail": "/uploads/placeholder-user.png",
      "placeholder": "/uploads/placeholder-user.png"
    },
    firstName: 'Sudhesh',
    middleName: '',
    lastName: 'Gorai',
    fatherName: '',
    dob: '',
    gender: 'male',
    addressLine1: '',
    addressLine2: 'Jamshedpur',
    landMark: '',
    state: 'Jharkhand',
    city: 'Jamshedpur',
    pinCode: '',
    higherEducation: '',
    occupation: '',
    profession: '',
    piDocumentType: 'piDocumentType',
    piDocumentId: 'piDocumentId',
    piDocumentPath: 'piDocumentPath',
    bloodGroup: '',
    clan: '',
  },
  {
    userId: 'saratGorai123',
    profileUrl: "sarat-gorai",
    memberId: 'saratGorai123',
    email: '',
    mobile: '',
    password: '',
    level: 1,
    isValidatedMobile: false,
    isValidatedEmail: false,
    firstTime: true,
    memberType: '1',
    profileImage: {
      "path": "/uploads/saratGorai.jpg",
      "thumbnail": "/uploads/placeholder-user.png",
      "placeholder": "/uploads/placeholder-user.png"
    },
    firstName: 'Sarat',
    middleName: '',
    lastName: 'Gorai',
    fatherName: '',
    dob: '',
    gender: 'male',
    addressLine1: '',
    addressLine2: 'Jamshedpur',
    landMark: '',
    state: 'Jharkhand',
    city: 'Jamshedpur',
    pinCode: '',
    higherEducation: '',
    occupation: '',
    profession: '',
    piDocumentType: 'piDocumentType',
    piDocumentId: 'piDocumentId',
    piDocumentPath: 'piDocumentPath',
    bloodGroup: '',
    clan: '',
  },
  {
    userId: 'sanjayGorai123',
    profileUrl: "sanjay-gorai",
    memberId: 'sanjayGorai123',
    email: '',
    mobile: '',
    password: '',
    level: 1,
    isValidatedMobile: false,
    isValidatedEmail: false,
    firstTime: true,
    memberType: '1',
    profileImage: {
      "path": "/uploads/sanjayGorai.jpg",
      "thumbnail": "/uploads/placeholder-user.png",
      "placeholder": "/uploads/placeholder-user.png"
    },
    firstName: 'Sanjay',
    middleName: '',
    lastName: 'Gorai',
    fatherName: '',
    dob: '',
    gender: 'male',
    addressLine1: '',
    addressLine2: 'Jamshedpur',
    landMark: '',
    state: 'Jharkhand',
    city: 'Jamshedpur',
    pinCode: '',
    higherEducation: '',
    occupation: '',
    profession: '',
    piDocumentType: 'piDocumentType',
    piDocumentId: 'piDocumentId',
    piDocumentPath: 'piDocumentPath',
    bloodGroup: '',
    clan: '',
  },
  {
    userId: 'sandeepGorai123',
    profileUrl: "sandeep-gorai",
    memberId: 'sandeepGorai123',
    email: '',
    mobile: '',
    password: '',
    level: 1,
    isValidatedMobile: false,
    isValidatedEmail: false,
    firstTime: true,
    memberType: '1',
    profileImage: {
      "path": "/uploads/sandeepGorai.jpg",
      "thumbnail": "/uploads/placeholder-user.png",
      "placeholder": "/uploads/placeholder-user.png"
    },
    firstName: 'Sandeep',
    middleName: '',
    lastName: 'Gorai',
    fatherName: '',
    dob: '',
    gender: 'male',
    addressLine1: '',
    addressLine2: 'Jamshedpur',
    landMark: '',
    state: 'Jharkhand',
    city: 'Jamshedpur',
    pinCode: '',
    higherEducation: '',
    occupation: '',
    profession: '',
    piDocumentType: 'piDocumentType',
    piDocumentId: 'piDocumentId',
    piDocumentPath: 'piDocumentPath',
    bloodGroup: '',
    clan: '',
  },
  {
    userId: 'rajuGoraSdhgora123',
    profileUrl: "raju-gorai-sidhgora",
    memberId: 'rajuGoraSdhgora123',
    email: '',
    mobile: '',
    password: '',
    level: 1,
    isValidatedMobile: false,
    isValidatedEmail: false,
    firstTime: true,
    memberType: '1',
    profileImage: {
      "path": "/uploads/rajuGoraSidhgora.jpg",
      "thumbnail": "/uploads/placeholder-user.png",
      "placeholder": "/uploads/placeholder-user.png"
    },
    firstName: 'Raju',
    middleName: '',
    lastName: 'Gorai',
    fatherName: '',
    dob: '',
    gender: 'male',
    addressLine1: 'Sidhgora',
    addressLine2: 'Jamshedpur',
    landMark: '',
    state: 'Jharkhand',
    city: 'Jamshedpur',
    pinCode: '',
    higherEducation: '',
    occupation: '',
    profession: '',
    piDocumentType: 'piDocumentType',
    piDocumentId: 'piDocumentId',
    piDocumentPath: 'piDocumentPath',
    bloodGroup: '',
    clan: '',
  },
  {
    userId: 'ganeshGorai123',
    profileUrl: "ganesh-gorai",
    memberId: 'ganeshGorai123',
    email: '',
    mobile: '',
    password: '',
    level: 1,
    isValidatedMobile: false,
    isValidatedEmail: false,
    firstTime: true,
    memberType: '1',
    profileImage: {
      "path": "/uploads/ganeshGorai.jpg",
      "thumbnail": "/uploads/placeholder-user.png",
      "placeholder": "/uploads/placeholder-user.png"
    },
    firstName: 'Ganesh',
    middleName: '',
    lastName: 'Gorai',
    fatherName: '',
    dob: '',
    gender: 'male',
    addressLine1: '',
    addressLine2: 'Jamshedpur',
    landMark: '',
    state: 'Jharkhand',
    city: 'Jamshedpur',
    pinCode: '',
    higherEducation: '',
    occupation: '',
    profession: '',
    piDocumentType: 'piDocumentType',
    piDocumentId: 'piDocumentId',
    piDocumentPath: 'piDocumentPath',
    bloodGroup: '',
    clan: '',
  },
  {
    userId: 'cmGorai123',
    profileUrl: "cm-gorai",
    memberId: 'cmGorai123',
    email: '',
    mobile: '',
    password: '',
    level: 1,
    isValidatedMobile: false,
    isValidatedEmail: false,
    firstTime: true,
    memberType: '1',
    profileImage: {
      "path": "/uploads/cmGorai.jpg",
      "thumbnail": "/uploads/placeholder-user.png",
      "placeholder": "/uploads/placeholder-user.png"
    },
    firstName: 'Dr. CM',
    middleName: '',
    lastName: 'Gorai',
    fatherName: '',
    dob: '',
    gender: 'male',
    addressLine1: '',
    addressLine2: 'Jamshedpur',
    landMark: '',
    state: 'Jharkhand',
    city: 'Jamshedpur',
    pinCode: '',
    higherEducation: '',
    occupation: '',
    profession: '',
    piDocumentType: 'piDocumentType',
    piDocumentId: 'piDocumentId',
    piDocumentPath: 'piDocumentPath',
    bloodGroup: '',
    clan: '',
  },
  {
    userId: 'babluGorai123',
    profileUrl: "bablu-gorai",
    memberId: 'bablu123',
    email: '',
    mobile: '',
    password: '',
    level: 1,
    isValidatedMobile: false,
    isValidatedEmail: false,
    firstTime: true,
    memberType: '1',
    profileImage: {
      "path": "/uploads/babluGorai.jpg",
      "thumbnail": "/uploads/placeholder-user.png",
      "placeholder": "/uploads/placeholder-user.png"
    },
    firstName: 'Bablu',
    middleName: '',
    lastName: 'Gorai',
    fatherName: '',
    dob: '',
    gender: 'male',
    addressLine1: 'Birsanagar',
    addressLine2: 'Jamshedpur',
    landMark: '',
    state: 'Jharkhand',
    city: 'Jamshedpur',
    pinCode: '',
    higherEducation: '',
    occupation: '',
    profession: '',
    piDocumentType: 'piDocumentType',
    piDocumentId: 'piDocumentId',
    piDocumentPath: 'piDocumentPath',
    bloodGroup: '',
    clan: '',
  },
  {
    userId: 'bholanath123',
    profileUrl: "bhola-nath-gorai",
    memberId: 'bholaNath123',
    email: '',
    mobile: '',
    password: '',
    level: 1,
    isValidatedMobile: false,
    isValidatedEmail: false,
    firstTime: true,
    memberType: '1',
    profileImage: {
      "path": "/uploads/bholaNathGorai.jpg",
      "thumbnail": "/uploads/placeholder-user.png",
      "placeholder": "/uploads/placeholder-user.png"
    },
    firstName: 'Bhola',
    middleName: 'Nath',
    lastName: 'Gorai',
    fatherName: '',
    dob: '',
    gender: 'male',
    addressLine1: 'Birsanagar',
    addressLine2: 'Jamshedpur',
    landMark: '',
    state: 'Jharkhand',
    city: 'Jamshedpur',
    pinCode: '',
    higherEducation: '',
    occupation: '',
    profession: '',
    piDocumentType: 'piDocumentType',
    piDocumentId: 'piDocumentId',
    piDocumentPath: 'piDocumentPath',
    bloodGroup: '',
    clan: '',
  },
  {
    userId: 'mahabir123',
    profileUrl: "mahabir-gorai",
    memberId: 'mahabir123',
    email: '',
    mobile: '',
    password: '',
    level: 1,
    isValidatedMobile: false,
    isValidatedEmail: false,
    firstTime: true,
    memberType: '1',
    profileImage: {
      "path": "/uploads/mahabirGorai.jpg",
      "thumbnail": "/uploads/placeholder-user.png",
      "placeholder": "/uploads/placeholder-user.png"
    },
    firstName: 'Mahabir',
    middleName: '',
    lastName: 'Gorai',
    fatherName: '',
    dob: '',
    gender: 'male',
    addressLine1: 'Mango',
    addressLine2: 'Jamshedpur',
    landMark: '',
    state: 'Jharkhand',
    city: 'Jamshedpur',
    pinCode: '',
    higherEducation: '',
    occupation: '',
    profession: '',
    piDocumentType: 'piDocumentType',
    piDocumentId: 'piDocumentId',
    piDocumentPath: 'piDocumentPath',
    bloodGroup: '',
    clan: '',
  },
  {
    userId: 'raju123',
    profileUrl: "raju-gorai",
    memberId: 'raju123',
    email: '',
    mobile: '',
    password: '',
    level: 1,
    isValidatedMobile: false,
    isValidatedEmail: false,
    firstTime: true,
    memberType: '1',
    profileImage: {
      "path": "/uploads/rajuGorai.jpg",
      "thumbnail": "/uploads/placeholder-user.png",
      "placeholder": "/uploads/placeholder-user.png"
    },
    firstName: 'Raju',
    middleName: '',
    lastName: 'Gorai',
    fatherName: '',
    dob: '',
    gender: 'male',
    addressLine1: 'Mango',
    addressLine2: 'Jamshedpur',
    landMark: '',
    state: 'Jharkhand',
    city: 'Jamshedpur',
    pinCode: '',
    higherEducation: '',
    occupation: '',
    profession: '',
    piDocumentType: 'piDocumentType',
    piDocumentId: 'piDocumentId',
    piDocumentPath: 'piDocumentPath',
    bloodGroup: '',
    clan: '',
  },
  {
    userId: 'karan123',
    profileUrl: "karan-gorai",
    memberId: 'karan123',
    email: '',
    mobile: '',
    password: '',
    level: 1,
    isValidatedMobile: false,
    isValidatedEmail: false,
    firstTime: true,
    memberType: '1',
    profileImage: {
      "path": "/uploads/karanGorai.jpg",
      "thumbnail": "/uploads/placeholder-user.png",
      "placeholder": "/uploads/placeholder-user.png"
    },
    firstName: 'Karan',
    middleName: '',
    lastName: 'Gorai',
    fatherName: '',
    dob: '',
    gender: 'male',
    addressLine1: 'Vidyapati Nagar',
    addressLine2: 'Jamshedpur',
    landMark: '',
    state: 'Jharkhand',
    city: 'Jamshedpur',
    pinCode: '',
    higherEducation: '',
    occupation: '',
    profession: '',
    piDocumentType: 'piDocumentType',
    piDocumentId: 'piDocumentId',
    piDocumentPath: 'piDocumentPath',
    bloodGroup: '',
    clan: '',
  },
  {
    userId: 'dayal123',
    profileUrl: "dayal-gorai",
    memberId: 'dayal123',
    email: '',
    mobile: '',
    password: '',
    level: 1,
    isValidatedMobile: false,
    isValidatedEmail: false,
    firstTime: true,
    memberType: '1',
    profileImage: {
      "path": "/uploads/dayalGorai.jpg",
      "thumbnail": "/uploads/placeholder-user.png",
      "placeholder": "/uploads/placeholder-user.png"
    },
    firstName: 'Dayal',
    middleName: '',
    lastName: 'Gorai',
    fatherName: '',
    dob: '',
    gender: 'male',
    addressLine1: 'Birsanagar',
    addressLine2: 'Jamshedpur',
    landMark: '',
    state: 'Jharkhand',
    city: 'Jamshedpur',
    pinCode: '',
    higherEducation: '',
    occupation: '',
    profession: '',
    piDocumentType: 'piDocumentType',
    piDocumentId: 'piDocumentId',
    piDocumentPath: 'piDocumentPath',
    bloodGroup: '',
    clan: '',
  },
  {
    userId: 'ranvir123',
    profileUrl: "ranvir-gorai",
    memberId: 'ranvir123',
    email: 'gorairanvir@gmail.com',
    mobile: '+919102794961',
    password: 'password',
    level: 1,
    isValidatedMobile: false,
    isValidatedEmail: false,
    firstTime: true,
    memberType: '1',
    profileImage: {
      "path": "/uploads/ranvir.jpg",
      "thumbnail": "/uploads/placeholder-user.png",
      "placeholder": "/uploads/placeholder-user.png"
    },
    firstName: 'Ranvir',
    middleName: '',
    lastName: 'Gorai',
    fatherName: 'Shambhu Gorai',
    dob: '15/01/1994',
    gender: 'male',
    addressLine1: 'Birsanagar',
    addressLine2: 'Jamshedpur',
    landMark: '',
    state: 'Jharkhand',
    city: 'Jamshedpur',
    pinCode: '831019',
    higherEducation: 'B. Tech (Computer Science & Engineering)',
    occupation: 'Software Engineer',
    profession: 'Software Engineer',
    piDocumentType: 'piDocumentType',
    piDocumentId: 'piDocumentId',
    piDocumentPath: 'piDocumentPath',
    bloodGroup: 'B+',
    clan: 'Kcchap',
  },
]

export default memberConfigData