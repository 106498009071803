import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import Particle from "../../Components/Particle.js";
import MemberCard from "./FeedCard";
import _sortBy from 'lodash/sortBy'
import _orderBy from 'lodash/orderBy'

import feeds from './mock'
import FeedCard from "./FeedCard";
import { useNavigate } from "react-router-dom";
import moment from "moment";


function Achiever() {
  const [filteredFeedData, setFilteredFeedData] = useState(feeds)
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  function onClick(data) {
    navigate(`/achiever/${data.feedId}`, { state: { id: data.feedId } });
  }

  useEffect(()=>{
    const sortedData=_orderBy(filteredFeedData, function(item) {
      return moment(item.date,'DD/MM/YYYY');
    },['desc']);
    setFilteredFeedData(sortedData)
  },[filteredFeedData,filteredFeedData.length])
  
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          {t('Our')} <strong className="mainText"> {t('Work')} </strong>
        </h1>
        <p style={{ color: "white" }}>
          {t("Our contribution through out the year")}
        </p>
        <Row
          style={{ justifyContent: "center", paddingBottom: "10px" }}
        >
          <InfiniteScroll
            dataLength={feeds.length}
            next={() => {
              // console.log("call next")
              // setFilteredFeedData([...feeds])
            }}
            // height={600}
            style={{
              justifyContent: "center",
              paddingBottom: "10px",
              overflow: 'auto',
              display: 'flex',
              flexWrap: 'wrap'
            }}
            inverse={false} //
            hasMore={true}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {filteredFeedData.map((item, index) => {
              return <FeedCard
                onClick={() => onClick(item)}
                key={item.feedId}
                media={item.media}
                feedId={item.feedId}
                approved={item.approved}
                archived={item.archived}
                created_at={item.created_at}
                date={item.date}
                deleted={item.deleted}
                feedMessage={item.feedMessage}
                headline={item.headline}
                likes={item.likes}
                location={item.location}
                members={item.members}
                updated_at={item.updated_at}
                userId={item.userId}
              />
            })}
          </InfiniteScroll>
        </Row>
      </Container>
    </Container>
  );
}

export default Achiever;
