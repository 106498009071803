import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/logo.png";
import Tilt from "react-parallax-tilt";
import { FaFacebook } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import TypeWriter from "../../Components/TypeWriter";


function Home2() {
  const { t, i18n } = useTranslation();
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>

        <Row>
        <h1><TypeWriter  /></h1>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              {t('Our purpose at')} <span className="mainText"> {t('GORAI COMMUNITY')}  </span> {t('is')} :
            </h1>
            <p className="home-about-body">
            {t('Here all the Gorai brothers have to be brought together! And build a huge society')}! 🤷‍♂️
              <br />
              <br />{t('Help deserving and needful people in')}
              <i>
                <b className="mainText"> {t('Education')}, {t("Health")}, {t('Job')}, {t("Matrimonial")} </b>{t("etc")}
              </i>
              <br />
              <br />
              {t("Recognize best candidates in")}&nbsp;
              <i>
                <b className="mainText">{t('Gorai')}</b> {t('and')}
                {t('provide help and support in')}{" "}
                <b className="mainText">
                {t('Education')} {t('Medical')} {t("etc")}
                </b>
              </i>
              <br />
              <br />
              {t('Meet people and understand their problems and provide optimal support and suggestion in')} <b className="mainText">{t('Medical')}</b> ,
              <i>
                <b className="mainText">
                  {" "}
                  {t('Education')}
                </b>
              </i>
              &nbsp;,
              <i>
                <b className="mainText"> {t('Carrier')}</b> {t('etc')}
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>{t("FIND US ON")}</h1>
            <p>
              {t('Feel free to')} <span className="mainText">{t("connect")} </span>{t("with us")}
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/groups/1512489479001705"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaFacebook />
                </a>
              </li>
        
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
