import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import Particle from "../../Components/Particle.js";
import MemberCard from "./FeedCard";
import _find from 'lodash/find'

import feeds from './mock'
import FeedCard from "./FeedCard";
import { useParams } from "react-router-dom";
import moment from "moment";


function AchievementDetail() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const item = _find(feeds, { feedId: id })

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          {item.headline} <strong className="mainText">  </strong>
        </h1>
        <p className="mainText">
          {moment(item.date,'DD/MM/YYYY').format('LL')}
        </p>
        <Row
          style={{ justifyContent: "center", paddingBottom: "10px" }}
        >
          <FeedCard
            detailed
            key={item.feedId}
            media={item.media}
            feedId={item.feedId}
            approved={item.approved}
            archived={item.archived}
            created_at={item.created_at}
            date={item.date}
            deleted={item.deleted}
            feedMessage={item.feedMessage}
            headline={item.headline}
            likes={item.likes}
            location={item.location}
            members={item.members}
            updated_at={item.updated_at}
            userId={item.userId}
          />

        </Row>
      </Container>
    </Container>
  );
}

export default AchievementDetail;
