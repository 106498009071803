import React from "react";
import { Container, Row, Col, Form, Button, Card, ButtonGroup } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../../Components/Particle.js"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import sliderConfig from '../../Assets/Slider'


function Login() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content ">
          <Row className="justify-content-md-center loginForm" >
       
            <Col md={4} >
           
              <Card bg={'secondary'} className="justify-content-md-center">
                <Card.Header>Login</Card.Header>
                <Card.Body>
                  {/* <Card.Title>Special title treatment</Card.Title> */}
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control type="email" placeholder="Enter Mobile Number" />
                      {/* <Form.Text className="text-error">
                        We'll never share your email with anyone else.
                      </Form.Text> */}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>OTP</Form.Label>
                      <Form.Control type="password" placeholder="Enter OTP" />
                    </Form.Group>
                    {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check type="checkbox" label="Check me out" />
                    </Form.Group> */}
                  <div className="loginButtonWrapper">
                  <Button variant="primary" type="submit" className="pull-right">
                      Submit
                    </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
        
              
            </Col>
          </Row>
        </Container>
      </Container>

    </section>
  );
}

export default Login;
